import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import fontawesome from '@fortawesome/fontawesome';
import solid from '@fortawesome/fontawesome-free-solid';
import brands from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import MultiSelect from 'vue-multiselect';
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import ProductZoomer from 'vue-product-zoomer';
import SearchResults from './views/SearchView';
import RelatedProducts from './components/product-view/ProductViewRelatedProducts';
import SearchPanel from './components/SearchPanel';
import SearchResultsB2b from './views/SearchB2bView';
import ConnectorTools from './views/ConnectorToolView';
import ImageGalleryPanel from './components/ImageGalleryPanel';
import { createStore } from './store';
import { SET_APP_DATA, SET_TOKEN, SET_B2B_FLAG, SET_THEME_COLORS } from './store/mutations';
import Router from 'vue-router';
import SelectSystemPanel from './components/filters-panel/FiltersPanelSelectSystem';

fontawesome.library.add(solid);
fontawesome.library.add(brands);

Vue.use(ProductZoomer);
Vue.use(VueLodash, lodash);
Vue.use(BootstrapVue);
Vue.component('multiselect', MultiSelect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Router);

// Expose a factory function that creates a fresh set of store, router,
// app instances on each call (which is called for each SSR request)
function createApp() {
  // create store and router instances
  const store = createStore();
  const router = new Router({ mode: 'history' });

  if (typeof appToken !== 'undefined') {
    store.commit(SET_TOKEN, appToken);
  }
  if (typeof appB2b !== 'undefined') {
    store.commit(SET_B2B_FLAG, appB2b);
  }
  if (typeof appB2b !== 'undefined' && appB2b) {
    store.commit(SET_THEME_COLORS, {
      startGradient,
      endGradient,
      b2bFontColor,
      b2bTitleColor
    });
  }

  const apps = [
    {
      elementId: 'searchPanel',
      component: SearchPanel,
    },
    {
      elementId: 'searchResults',
      component: SearchResults,
    },
    {
      elementId: 'connectorTools',
      component: ConnectorTools,
    },
    {
      elementId: 'relatedProducts',
      component: RelatedProducts,
    },
    {
      elementId: 'searchResultsB2b',
      component: SearchResultsB2b,
    },
    {
      elementId: 'selectSystemPanel',
      component: SelectSystemPanel,
    },
    {
      elementId: 'imageGalleryPanel',
      component: ImageGalleryPanel,
    },
  ];

  apps.forEach(app => {
    if (document.getElementById(app.elementId)) {
      store.commit(SET_APP_DATA, {
        appName: app.elementId,
        data: document.getElementById(app.elementId).dataset,
      });
      app.app = new Vue({
        el: `#${app.elementId}`,
        store,
        router,
        render: h => h(app.component),
      });
    }
  });
}

$(createApp);
