<template>
  <div class="container1 bluebar_inner clearfix">
    <div class="lftpanel"><span>SYSTEM CONFIGURATION SEARCH</span></div>
    <ul>
      <li class="select-type">
        <b-dropdown
          v-model="searchType.value"
          :text="searchType.text"
          class="btn-select-type"
          menu-class="search-type__menu"
          toggle-class="search-type__dropdown">
          <b-dropdown-item
            v-for="item in typeOptions"
            :key="item.value"
            :value="item.value"
            @click="searchType = item">{{ item.text }}</b-dropdown-item>
        </b-dropdown>
      </li>

      <li class="select-system" v-if="!showSearchText">
        <multiselect
          v-model="searchBrand"
          :options="brandOptions"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :hide-selected="false"
          :preserve-search="true"
          :show-labels="false"
          placeholder="Select OEM"
          label="name"
          track-by="name"
          class="custom-multiselect custom-multiselect--left custom-multiselect--square"
        >
          <template
            slot="tag"
            slot-scope="props">
            {{ props.option.name }}
          </template>
        </multiselect>
      </li>

      <li
        class="keyword fieldsForOther"
        v-if="showSearchText"
        style="width: 51.6%;"
      >
        <b-form-input
          v-model="searchText"
          type="text"
          placeholder="Type Keyword Here"
          class="search__text-field"
          @keydown.enter.native="doSearchProducts"
        />
      </li>

      <li class="select-system" v-if="!showSearchText">
          <multiselect
            id="ajax"
            v-model="searchSystem"
            :options="systemOptions"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="true"
            :hide-selected="false"
            :preserve-search="true"
            :options-limit="300"
            :show-labels="false"
            placeholder="Select System"
            label="name"
            track-by="name"
            class="custom-multiselect custom-multiselect--square custom-multiselect--left"
            @search-change="asyncFindSystem"
            @select="goToProductPage"
          >
            <template
              slot="tag"
              slot-scope="props">
              {{ props.option.name }}
            </template>
          </multiselect>
      </li>


      <li class="last">
        <button
          class="btn search_btn"
          @click="doSearchProducts"
        >
          GO
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  LOAD_PRODUCT_SYSTEMS,
  LOAD_PRODUCT_BRANDS,
  SAVE_SELECTED_FILTERS_AND_SEARCH,
} from 'store/actions';
import {
  GET_PRODUCT_SYSTEMS,
  GET_PRODUCT_BRANDS,
  GET_SELECTED_FILTERS,
  GET_UPDATE_SEARCH_PANEL,
} from 'store/getters';
import { SET_SELECTED_FILTERS } from 'store/mutations';
import productsMixin from '../mixins/products';
import searchHistoryMixin from '../mixins/searchHistory';

export default {
  name: 'SearchPanel',
  data() {
    return {
      list: [],
      searchType: {
        value: 'all',
        text: 'All',
      },
      searchBrand: null,
      searchSystem: null,
      typeOptions: [
        {
          value: 'all',
          text: 'All',
        },
        {
          value: 'system',
          text: 'System',
        },
        {
          value: 'sku',
          text: 'Part #',
        },
      ],
      searchText: '',
    };
  },
  mixins: [productsMixin, searchHistoryMixin],
  computed: {
    ...mapGetters({
      systemOptions: GET_PRODUCT_SYSTEMS,
      brandOptions: GET_PRODUCT_BRANDS,
      selectedFilters: GET_SELECTED_FILTERS,
      isUpdateSearchPanel: GET_UPDATE_SEARCH_PANEL,
    }),

    /**
     *  Check if selected type system.
     *
     *  @return boolean
     */
    isSystemType() {
      return this.searchType.value === 'system';
    },

    /**
     *  Check if need show search text field.
     *
     *  @return boolean
     */
    showSearchText() {
      return !this.isSystemType;
    },

    dataset() {
      return this.$store.state.searchPanel;
    },
  },
  watch: {
    brandOptions(newValue) {
      if (this.searchBrand && this.searchBrand.id && newValue) {
        this.searchBrand = newValue.find(el => parseInt(el.id, 10) === parseInt(this.searchBrand.id, 10))
      }
    },

    /**
     *  Upload list of product systems when brand changed.
     */
    searchBrand(newValue, oldValue) {
      if (newValue) {
        if (oldValue && oldValue.id !== newValue.id) {
          this.searchSystem = {};
        }
        this[LOAD_PRODUCT_SYSTEMS]({oemId: newValue ? newValue.id : null});
      }
    },

    /**
     *  Update filters in view if changed in store.
     */
    selectedFilters(newValue) {
      if (
        newValue.type !== 'relatedProduct' &&
        newValue.type !== 'connectors' &&
        this.isUpdateSearchPanel
      ) {
        this.searchText = newValue.term;
        this.searchType = this.typeOptions.find(el => el.value === newValue.type);
        if (newValue.manufacturers && newValue.manufacturers.length === 1) {
          this.searchBrand = newValue.manufacturers[0];
        }
      }
    },
  },
  mounted() {
    this[LOAD_PRODUCT_BRANDS]();
    this[LOAD_PRODUCT_SYSTEMS]();
    if (this.dataset.searchType) {
      this.searchType = this.typeOptions.find(el => el.value === this.dataset.searchType);
    }

    if (this.dataset.brandId) {
       this.searchBrand = {
         id: parseInt(this.dataset.brandId, 10),
         name: this.dataset.brandName,
       }
    }

    if (this.dataset.systemId) {
      this.searchSystem = {
        id: this.dataset.systemId,
        name: this.dataset.systemName,
      }
    }
  },
  methods: {
    ...mapActions([
      LOAD_PRODUCT_SYSTEMS,
      LOAD_PRODUCT_BRANDS,
      SAVE_SELECTED_FILTERS_AND_SEARCH,
    ]),

    /**
     * Redirect to system page.
     */
    goToProductPage(system) {
      window.location = this.getProductUrl(system, this.$store.state);
    },

    /**
     *  Redirect to search page and search products.
     */
    doSearchProducts() {
      let url = '';

      if (!this.isSystemType) {
        url = '/search/' + encodeURIComponent(this.searchType.value) + '/' + encodeURIComponent(this.searchText);
      } else {
          url = '/search/system/' + encodeURIComponent(this.searchBrand.name);
      }

      if (!!parseInt(this.$store.state.searchPanel.isSearchPage, 10)) {
        this.changeUrl(url);
        this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
          key: SET_SELECTED_FILTERS,
          data: {
            searchBrand: this.searchBrand,
            manufacturers:
              this.searchBrand && this.isSystemType ? [this.searchBrand] : [],
            type: this.searchType.value,
            term: !this.isSystemType ? this.searchText : '',
          },
        });
      } else {
        window.location.assign(url);
      }
    },

    /**
     *  Upload list of product systems by search text.
     *
     *  @params {String} searchText - text to search systems.
     */
    asyncFindSystem(searchText) {
      this[LOAD_PRODUCT_SYSTEMS]({
        oemId: this.searchBrand ? this.searchBrand.id : null,
        name: searchText,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../css/variables';
@import '../css/mixins';

.mw-920px {
  max-width: 920px;
}

.wrapper {
  padding: 10px 0;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.search__text-field.form-control {
  width: 100%;
  margin: 0;
  border: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
  color: #606c74;
  font-weight: 500;
}
.form-control {
  width: auto;
  margin: 0 15px;
  @include media('<=sm') {
    padding: 10px;
    margin: 0;
    font-size: 11px;
  }
}
</style>

<style lang="scss">
@import '../css/mixins';

$panel-background-color-from: #f7313f;

.custom-multiselect {
  .multiselect__tags {
    min-height: 35px;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight::after {
    background: $panel-background-color-from;
  }

  &--left {
    .multiselect__content-wrapper {
      left: 0;
      min-width: 100%;
      width: auto;
      @include media('<=sm') {
        width: 100%;
      }
    }
  }

  &--square {
    .multiselect__tags {
      border: 1px solid #eee;
      border-radius: 0;
      box-shadow: none;
      color: #999999;
    }
  }

  &--right {
    .multiselect__content-wrapper {
      right: 0;
      min-width: 100%;
      width: auto;

      @include media('<=sm') {
        width: 100%;
      }
    }
  }
}

.search-type__dropdown {
  width: 100%;

  &:active,
  &:focus {
    border-color: #545b62;
    box-shadow: none;
  }
}

.search-type__menu {
  & > .dropdown-item {
    color: white;
    &:hover,
    &:focus,
    &:visited {
      color: black;
    }
    &:active {
      color: white;
      background-color: $panel-background-color-from;
    }
  }
  background-color: #545b62;
  color: #fff;
  margin: 0;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 1em;
}

li.select-type {
  margin-top: 0px !important;
}

.btn-select-type .btn-secondary,
.btn-select-type .btn-secondary:focus,
.btn-select-type:hover .btn-secondary{
  background-color: #dbe6ed !important;
  color: #777 !important;
  border: none;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  padding: 7px 13px;
  text-transform: capitalize;
  height: 35px;
}

.btn-select-type .dropdown-menu,
.btn-select-type:hover .dropdown-menu{
  background-color: #dbe6ed !important;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  padding: 0px;
  li,li:focus  {
    background-color: #fff !important;
    border-bottom:1px solid #c3c3c3;
  }
  a ,a:focus{
    background-color: #fff !important;
    color: #777 !important;
    height: 40px;
  }
}


.btn-select-type {
  width: 100%;
}

.btn-select-type .dropdown-toggle::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  float: left;
  margin-top: 10px;
  margin-right: 13px;
}

li.keyword {
  height: 35px;
  color: #606c74;
}

  li .search_btn {
    height: 35px;
    font-size: 15px;
    padding: 0px;
    font-weight: 400;
    background: #dbe6ed;
    color: #777;
    width: 80px;
  }

li .search_btn:hover {
  background: #65cf40;
  color: #fff;
}

.select-system {
  margin-right: 1%;

  .multiselect__single {
    height: 34px !important;
    background-color: #dbe6ed;
    font-size: 15px !important;
    padding: 0px;
    font-weight: 400;
    color: #777 !important;
    line-height: 35px !important;
    overflow: hidden;
  }
  .multiselect__select {
    height: 35px;
  }
  .multiselect__option {
    width: 100% !important;
  }
  .multiselect__input {
    font-size: 15px !important;
    font-weight: 400 !important;
    color: #777 !important;
    padding-left: 0px !important;
    line-height: 35px !important;
  }
  .multiselect__placeholder,
  .multiselect__single{
    padding-left: 10px;
    font-size: 15px;
  }
}

.select-system .multiselect {
  height: 35px;
  min-height: 35px;
}

.select-system .multiselect__tags {
  padding: 0px !important;
  min-height: 35px !important;
  height: 35px !important;
  background-color: #dbe6ed;

}
</style>
