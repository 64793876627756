export default {
  methods: {
    /**
     * Return link to product page.
     *
     * @param {Object} product - Product data.
     * @param {boolean} state - Vuex state.
     * @return {string}
     */
    getProductUrl(product, state) {
      const productTerm = product.productDetailsTerm || product.id;
      if (state.b2b) {
        return `/b2b/${encodeURIComponent(productTerm)}?token=${
          state.token
        }`;
      } else {
        return `/product/${encodeURIComponent(productTerm)}`;
      }
    },

    /**
     * Init filters by params in dataset.
     */
    initFilters() {
      let initFilters = {};
      initFilters.type = this.dataset.type ? this.dataset.type : 'all';

      if (this.dataset.brandId) {
        initFilters.manufacturers = [{ id: this.dataset.brandId }];
      }

      if (this.dataset.categoryId) {
        initFilters.rootCategoryId = this.dataset.categoryId;
      }

      if (this.dataset.term) {
        initFilters.term = this.dataset.term;
      }

      if (this.dataset.attributeId) {
        initFilters.attributes = [
          {
            id: this.dataset.attributeId,
            items: [this.dataset.attributeValue],
          },
        ];
      }

      if (this.dataset.attributes) {
        initFilters.attributes = [];
        const attributes = JSON.parse(decodeURIComponent(this.dataset.attributes));
        for (var attributeId in attributes) {
          initFilters.attributes.push({
            id: parseInt(attributeId, 10),
            items: attributes[attributeId],
          });
        }
      }

      if (this.dataset.categories) {
        initFilters.categories = [];
        this.dataset.categories.split('|')
          .forEach( catId => initFilters.categories.push({id: parseInt(catId, 10)}));
      }
      if (this.dataset.brands) {
        initFilters.manufacturers = [];
        this.dataset.brands.split('|')
          .forEach( brandId => initFilters.manufacturers.push({id: parseInt(brandId, 10)}));
      }


      return initFilters;
    },
  },
};
