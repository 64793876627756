<template>
  <div v-if="showTypes">
    <b-button-group
        vertical
        class="w-100 mb-3 type-buttons"
    >
      <b-button
        :disabled="!filters.typeCounters.sku"
        :variant="skuOrSystemVariant('sku')"
        @click="toggleSkuOrSystem('sku')"
      >
        SKU
        <span v-if="filters.typeCounters.sku">
          ({{ filters.typeCounters.sku }})
        </span>
      </b-button>
      <b-button
        :disabled="!filters.typeCounters.system"
        :variant="skuOrSystemVariant('system')"
        @click="toggleSkuOrSystem('system')"
      >
        Systems
        <span v-if="filters.typeCounters.system">
          ({{ filters.typeCounters.system }})
        </span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SvgIcon from '../SvgIcon.vue';
import { GET_SELECTED_FILTERS, GET_FILTERS } from '../../store/getters';
import { CHANGE_SELECTED_FILTERS } from '../../store/actions';

import { SET_SELECTED_SKU_OR_SYSTEM } from '../../store/mutations';

export default {
  name: 'FiltersPanelTypes',
  components: {
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      selectedFilters: GET_SELECTED_FILTERS,
      filters: GET_FILTERS,
    }),
    /**
     *  Check if need show types panel.
     *
     * @return boolean
     */
    showTypes() {
      return Boolean(
        this.filters &&
          this.filters.typeCounters &&
          (this.filters.typeCounters.sku || this.filters.typeCounters.system),
      );
    },
  },
  methods: {
    ...mapActions([CHANGE_SELECTED_FILTERS]),

    /**
     *  Check if parameter skuOrSystem is set to "sku" or "system".
     *
     * @param {String} type - value for skuOrSystem.
     * @return boolean
     */
    isSkuOrSystem(type) {
      return this.selectedFilters.skuOrSystem === type;
    },
    /**
     *  Return variant for type counters button. "dark" if selected, empty if not.
     *
     * @param {String} type - value for skuOrSystem.
     * @return string
     */
    skuOrSystemVariant(type) {
      return this.isSkuOrSystem(type) ? 'dark' : '';
    },
    /**
     *  Save parameter skuOrSystem to selected filters.
     *
     * @param {String} type - value for skuOrSystem.
     */
    toggleSkuOrSystem(type) {
      this[CHANGE_SELECTED_FILTERS]({
        key: SET_SELECTED_SKU_OR_SYSTEM,
        data: type,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/css/variables.scss';

.type-buttons {
  button {
    background-color: $panel-bg-color;
    &:hover,
    &:focus {
      background-color: darken($panel-bg-color, 30%);
      color: white;
      border-color: darken($panel-bg-color, 30%);
      box-shadow: none;
    }
  }
}

</style>
