<template>
  <div class="mb-3">
    <multiselect
      v-model="selectedOptions"
      :options="options"
      :multiple="true"
      group-values="items"
      group-label="groupLabel"
      :group-select="true"
      :show-labels="false"
      placeholder="Type to search"
      track-by="groupId"
      label="name"
      @remove="removeOption"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SvgIcon from '../SvgIcon.vue';
import { GET_SELECTED_FILTERS, GET_FILTERS } from '../../store/getters';
import {
  REMOVE_SELECTED_ATTRIBUTE,
  CHANGE_SELECTED_FILTERS,
} from '../../store/actions';
import {
  SET_SELECTED_ATTRIBUTES,
  SET_SELECTED_BRANDS,
  SET_SELECTED_CATEGORIES,
} from '../../store/mutations';

const CATEGORIES_GROUP = 'categories';
const BRANDS_GROUP = 'brands';
const ATTRIBUTES_GROUP = 'attributes';

export default {
  name: 'FiltersPanelSelectOptions',
  components: {
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      selectedFilters: GET_SELECTED_FILTERS,
      filters: GET_FILTERS,
    }),

    /**
     * Selected options of multi select.
     */
    selectedOptions: {
      get() {
        return this.getSelectedOptions();
      },
      set(options) {
        return this.setSelectedOptions(options);
      },
    },

    /**
     * All options of multi select.
     */
    options() {
      return this.getOptions();
    },
  },
  methods: {
    ...mapActions({
      removeAttribute: REMOVE_SELECTED_ATTRIBUTE,
      changeFilters: CHANGE_SELECTED_FILTERS,
    }),

    /**
     * Remove attribute from selected filters when it removed from multi select.
     *
     * @param {Object} option - Option removed from multi select.
     */
    removeOption(option) {
      if (option.group === ATTRIBUTES_GROUP) {
        this.removeAttribute({
          id: option.attributeId,
          value: option.id,
        });
      }
    },

    /**
     * Setter for selected options.
     *
     * @param {array} selectedOptions - Selected options in multi select.
     */
    setSelectedOptions(selectedOptions) {
      const categories = selectedOptions
        .filter(opt => opt.group === CATEGORIES_GROUP)
        .map(opt => opt.id);
      this.changeFilters({
        key: SET_SELECTED_CATEGORIES,
        data: { items: categories, id: CATEGORIES_GROUP },
      });

      const brands = selectedOptions
        .filter(opt => opt.group === BRANDS_GROUP)
        .map(opt => opt.id);
      this.changeFilters({
        key: SET_SELECTED_BRANDS,
        data: { items: brands, id: BRANDS_GROUP },
      });

      let attributes = [];
      selectedOptions
        .filter(opt => opt.group === ATTRIBUTES_GROUP)
        .forEach(attr => {
          let attribute = attributes.find(el => el.id === attr.attributeId);
          if (!attribute) {
            attributes.push({
              id: attr.attributeId,
              items: [attr.id],
            });
          } else {
            attribute.items.push(attr.id);
          }
        });

      attributes.forEach(attribute => {
        this.changeFilters({
          key: SET_SELECTED_ATTRIBUTES,
          data: attribute,
        });
      });
    },

    /**
     * Returns selected options.
     *
     * @return {Array}
     */
    getSelectedOptions() {
      let options = [];
      if (this.selectedFilters.categories) {
        this.selectedFilters.categories.forEach((el, index) => {
          if (el) {
            options.push(this.categoryOption(el));
          } else {
            this.selectedFilters.categories.splice(index, 1);
          }
        });
      }

      if (this.selectedFilters.manufacturers) {
        this.selectedFilters.manufacturers.forEach((brand, index) => {
          if (brand) {
            options.push(this.brandOption(brand));
          } else {
            this.selectedFilters.manufacturers.splice(index, 1);
          }
        });
      }

      if (this.selectedFilters.attributes) {
        this.selectedFilters.attributes.forEach(attribute => {
          attribute.items.forEach(val => {
            options.push(this.attributeOption(attribute, val));
          });
        });
      }

      return options;
    },

    /**
     * Returns category option object.
     *
     * @param {array} category - Category info.
     *
     * @return {Array}
     */
    categoryOption(category) {
      return {
        groupId: `category-${category.id}`,
        group: CATEGORIES_GROUP,
        name: category.name,
        id: category.id,
        $isDisabled: !category.productsCount,
      };
    },

    /**
     * Returns brand option object.
     *
     * @param {array} brand - Brand info.
     *
     * @return {Array}
     */
    brandOption(brand) {
      return {
        groupId: `brand-${brand.id}`,
        group: BRANDS_GROUP,
        name: brand.name,
        id: brand.id,
        $isDisabled: !brand.productsCount,
      };
    },

    /**
     * Returns attribute option object.
     *
     * @param {array} attribute - Attribute info.
     * @param {array} value - Attribute value.
     *
     * @return {Array}
     */
    attributeOption(attribute, value) {
      return {
        groupId: `attribute-${attribute.id}-${value}`,
        attributeId: attribute.id,
        group: ATTRIBUTES_GROUP,
        id: value,
        name: value,
      };
    },

    /**
     * Returns options for multi select.
     *
     * @return {Array}
     */
    getOptions() {
      let options = [];

      if (this.filters.categories) {
        options.push({
          group: CATEGORIES_GROUP,
          groupLabel: 'Categories',
          items: this.filters.categories.map(el => {
            return this.categoryOption(el);
          }),
        });
      }

      if (this.filters.manufacturers) {
        options.push({
          group: BRANDS_GROUP,
          groupLabel: 'EOM',
          items: this.filters.manufacturers.map(el => {
            return this.brandOption(el);
          }),
        });
      }

      if (this.filters.attributes) {
        this.filters.attributes.forEach(attribute => {
          options.push({
            group: ATTRIBUTES_GROUP,
            groupLabel: attribute.name,
            items: attribute.data.map(value => {
              return this.attributeOption(attribute, value.value);
            }),
          });
        });
      }

      return options;
    },
  },
};
</script>
