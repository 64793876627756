<template>
  <main class="base-wrapper connector-tool-wrapper">
    <div
      class="container-fluid mb-4 mw-720px"
    >
      <div class="row">
        <div class="col-12 header">
          <h2 class="header-blue">
            MAKE THE RIGHT CONNECTION
          </h2>
          <h2 class="header-grey">
            WITH THE CONNECTOR TOOL.
          </h2>
        </div>
      </div>
      <b-row
        class="justify-content-sm-start justify-content-start align-items-start"
      >
        <div class="step">
          <button class="btn btn-secondary w-100 mb-4 btn-connector">
            1. category
          </button>
          <multiselect
            id="ajax"
            v-model="connectorCategory"
            :options="connectorCategories"
            :multiple="false"
            :close-on-select="true"
            :hide-selected="false"
            :show-labels="false"
            placeholder="Select Category"
            label="name"
            track-by="name"
            class="custom-multiselect custom-multiselect--square custom-multiselect--left connector-category"
          />
            <template
              slot="tag"
              slot-scope="props"
            >
              {{ props.option.name }}
            </template>
          </multiselect>
        </div>
        <div class="step">
          <button class="btn btn-secondary w-100 mb-4 btn-connector">
            2. type
          </button>
          <multiselect
            id="ajax"
            v-model="connectorTypeOne"
            :options="connectorTypesOne"
            :multiple="false"
            :close-on-select="true"
            :hide-selected="false"
            :show-labels="false"
            :class="{ error: connectorOneError }"
            placeholder="Select Type"
            label="value"
            track-by="value"
            class="custom-multiselect custom-multiselect--square custom-multiselect--left"
          >
            <template
              slot="tag"
              slot-scope="props"
            >
              {{ props.option.value }}
            </template>
          </multiselect>
          <div v-if="connectorTypeOne">
            <b-form-radio-group
              v-model="typeOneGender"
              :options="connectorTypeOne.genders"
              class="radio connector-tool-radio"
              stacked
              name="typeOneGender"
            />
            <img
              v-if="imageExists(connectorTypeOne, typeOneGender)"
              :src="getConnectorImage(connectorTypeOne, typeOneGender)"
              class="connector-image"
            >
          </div>
        </div>
        <div class="step">
          <button class="btn btn-secondary w-100 mb-4 btn-connector">
            3. type
          </button>
          <multiselect
            id="ajax"
            v-model="connectorTypeTwo"
            :options="connectorTypesTwo"
            :multiple="false"
            :close-on-select="true"
            :hide-selected="false"
            :show-labels="false"
            :class="{ error: connectorTwoError }"
            :disabled="!connectorTypesTwo.length"
            placeholder="Select Type"
            label="value"
            track-by="value"
            class="custom-multiselect custom-multiselect--square custom-multiselect--left"
          >
            <template
              slot="tag"
              slot-scope="props"
            >
              {{ props.option.value }}
            </template>
          </multiselect>
          <div v-if="connectorTypeTwo">
            <b-form-radio-group
              v-model="typeTwoGender"
              :options="connectorTypeTwo.genders"
              class="radio connector-tool-radio"
              stacked
              name="typeTwoGender"
            />
            <img
              v-if="imageExists(connectorTypeTwo, typeTwoGender)"
              :src="getConnectorImage(connectorTypeTwo, typeTwoGender)"
              class="connector-image"
            >
          </div>
        </div>
        <button
            class="btn btn-go"
            @click="search()">
          Go
        </button>
      </b-row>
    </div>
    <div
      v-if="showResults"
      class="container mb-4 results-container"
    >
<!--      <floating-points-loader :loading="isSearchLoading"/>-->
      <filters-panel class="filters-panel"/>
      <product-list v-if="!isSearchLoading" class="results-list"/>
      <div class="clearfix"/>
    </div>
    <svg-sprite />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  LOAD_CONNECTOR_CATEGORIES,
  LOAD_CONNECTOR_TYPES_ONE,
  LOAD_CONNECTOR_TYPES_TWO,
  SEARCH_BY_CONNECTOR,
  SAVE_SELECTED_FILTERS_AND_SEARCH,
} from '../store/actions';
import {
  GET_CONNECTOR_CATEGORIES,
  GET_CONNECTOR_TYPES_ONE,
  GET_CONNECTOR_TYPES_TWO,
  GET_HISTORY_FILTERS,
  GET_IS_SEARCH_LOADING,
} from '../store/getters';
import {
  SET_HIDE_SEARCH_VIEW,
  SET_SEARCH_ID,
  SET_SELECTED_FILTERS,
  SET_CONNECTOR_TYPES_TWO,
} from '../store/mutations';
import FiltersPanel from '../components/filters-panel/FiltersPanel.vue';
import ProductList from '../components/product-list/ProductList.vue';
import CoverSpin from '../components/CoverSpin.vue';
import SearchView from './SearchView.vue';
import FloatingPointsLoader from '../components/FloatingPointsLoader.vue';
import productsMixin from '../mixins/products';
import searchHistoryMixin from '../mixins/searchHistory';
import SvgSprite from '../components/SvgSprite.vue';

const DEFAULT_GENDER = 'either';

export default {
  name: 'ConnectorToolView',
  components: {
    FiltersPanel,
    ProductList,
    CoverSpin,
    SearchView,
    FloatingPointsLoader,
    SvgSprite,
  },
  mixins: [productsMixin, searchHistoryMixin],
  data() {
    return {
      isInitPage: true,
      connectorOneError: false,
      connectorTwoError: false,
      connectorCategory: null,
      connectorTypeOne: null,
      connectorTypeTwo: null,
      typeOneGender: DEFAULT_GENDER,
      typeTwoGender: DEFAULT_GENDER,
      isLoading: true,
      showResults: false,
      genders: ['male', 'female', 'either'],
    };
  },
  computed: {
    ...mapGetters({
      connectorCategories: GET_CONNECTOR_CATEGORIES,
      connectorTypesOne: GET_CONNECTOR_TYPES_ONE,
      connectorTypesTwo: GET_CONNECTOR_TYPES_TWO,
      getHistoryFilters: GET_HISTORY_FILTERS,
      isSearchLoading: GET_IS_SEARCH_LOADING,
    }),
  },
  watch: {
    /**
     *  Load connector types one when change category.
     */
    connectorCategory(newValue) {
      if (newValue && newValue.id && !this.isInitPage) {
        this[LOAD_CONNECTOR_TYPES_ONE]({
          categoryId: this.connectorCategory ? this.connectorCategory.id : null,
        }).then(() => {
          if (
            this.connectorTypeOne &&
            !this.connectorTypesOne
              .map(el => el.value)
              .includes(this.connectorTypeOne.value)
          ) {
            this.connectorTypeOne = null;
            this.typeOneGender = DEFAULT_GENDER;
          }
        });
      }
    },

    /**
     *  Load connector types two when change one gender.
     */
    typeOneGender() {
      this.updateConnectorTwo();
    },

    /**
     *  Load connector types two when change one.
     */
    connectorTypeOne() {
      this.updateConnectorTwo();
    },

    /**
     *  Clear required error.
     *
     *  @return boolean
     */
    connectorTypeTwo(newValue) {
      if (newValue && newValue.value) {
        this.connectorTwoError = false;
      }
    },
  },
  mounted() {
    this.initSearchPage();
  },
  methods: {
    ...mapActions([
      LOAD_CONNECTOR_CATEGORIES,
      LOAD_CONNECTOR_TYPES_ONE,
      LOAD_CONNECTOR_TYPES_TWO,
      SEARCH_BY_CONNECTOR,
      SAVE_SELECTED_FILTERS_AND_SEARCH,
    ]),
    ...mapMutations([
      SET_HIDE_SEARCH_VIEW,
      SET_SEARCH_ID,
      SET_SELECTED_FILTERS,
      SET_CONNECTOR_TYPES_TWO,
    ]),

    /**
     * Check if connector image is not empty
     */
    imageExists(connector, gender) {
      return this.getConnectorImage(connector, gender) !== '';
    },

    /**
     * Get connector image URL
     */
    getConnectorImage(connector, gender) {
      let img;
      return connector.genders.some(g => {
        img = g.image;
        return g.value === gender;
      })
        ? img
        : '';
    },

    /**
     * Update connector type two.
     */
    updateConnectorTwo() {
      if (
        this.connectorTypeOne &&
        this.connectorTypeOne.value &&
        !this.isInitPage
      ) {
        this.connectorOneError = false;
        this[LOAD_CONNECTOR_TYPES_TWO]({
          categoryId: this.connectorCategory ? this.connectorCategory.id : null,
          connector: this.connectorTypeOne.value,
          gender: this.typeOneGender,
        }).then(() => {
          if (
            this.connectorTypeTwo &&
            !this.connectorTypesTwo
              .map(el => el.value)
              .includes(this.connectorTypeTwo.value)
          ) {
            this.connectorTypeTwo = null;
            this.typeTwoGender = DEFAULT_GENDER;
          }
        });
      } else {
        this[SET_CONNECTOR_TYPES_TWO]([]);
        this.connectorTypeTwo = null;
        this.typeTwoGender = DEFAULT_GENDER;
      }
    },

    /**
     * Init filters by searchId if exists.
     */
    initSearchPage() {
      this.isInitPage = true;

      const searchId = this.initSearchId();
      this[SET_SEARCH_ID](searchId);
      const searchFilters = this.getHistoryFilters(searchId);
      if (searchFilters) {
        this.showResults = true;
        this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
          key: SET_SELECTED_FILTERS,
          data: searchFilters.filters,
          keepSelectedPage: true,
        });

        this[LOAD_CONNECTOR_CATEGORIES]().then(() => {
          this.connectorCategory = searchFilters.filters.connectors.category;
          this[LOAD_CONNECTOR_TYPES_ONE]({
            categoryId: this.connectorCategory ? this.connectorCategory.id : null,
          }).then(() => {
            this.typeOneGender =
              searchFilters.filters.connectors.connectorOne.gender;
            this.connectorTypeOne = this.connectorTypesOne.find(
              type =>
                type.value ===
                searchFilters.filters.connectors.connectorOne.type,
            );
            this[LOAD_CONNECTOR_TYPES_TWO]({
              categoryId: this.connectorCategory ? this.connectorCategory.id : null,
              connector: this.connectorTypeOne.id,
              gender: this.typeOneGender,
            }).then(() => {
              this.typeTwoGender =
                searchFilters.filters.connectors.connectorTwo.gender;
              this.connectorTypeTwo = this.connectorTypesTwo.find(
                type =>
                  type.value ===
                  searchFilters.filters.connectors.connectorTwo.type,
              );
              this.isInitPage = false;
            });
          });
        });
        return;
      }

      this[LOAD_CONNECTOR_CATEGORIES]().then(() => {
        this.connectorCategory = this.connectorCategories.find(
          cat => cat.id === 1,
        );
        this[LOAD_CONNECTOR_TYPES_ONE]({
          categoryId: this.connectorCategory ? this.connectorCategory.id : null,
        }).then(() => {
          this.isInitPage = false;
        });
      });
    },
    /**
     * Search connectors.
     */
    search() {
      this.connectorOneError = false;
      this.connectorTwoError = false;
      if (!this.connectorTypeOne || !this.connectorTypeOne.value) {
        this.connectorOneError = true;
      }
      if (!this.connectorTypeTwo || !this.connectorTypeTwo.value) {
        this.connectorTwoError = true;
      }

      if (this.connectorOneError || this.connectorTwoError) {
        return;
      }

      this.showResults = true;
      this[SEARCH_BY_CONNECTOR]({
        connectorOne: {
          type: this.connectorTypeOne.value,
          gender: this.typeOneGender,
        },
        connectorTwo: {
          type: this.connectorTypeTwo.value,
          gender: this.typeTwoGender,
        },
        category: this.connectorCategory.id === 1 ? null : this.connectorCategory,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../css/variables';
.header-grey {
  color: #58585b;
  text-align: left;
}
.header-blue {
  font-family: 'Helvetica', Arial, sans-serif;
  text-align: left;
  color: $light-blue;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0;
}
h2 {
  text-align: center;
  font-weight: bold;
}
.header {
  text-align: center;
}
.divider {
  width: 100px;
  margin: auto;
  border-bottom: 2px solid $panel-background-color-from;
  line-height: 1px;
}
.btn-go {
  font-family: 'Helvetica', Arial, sans-serif;
  background-color: $go-color;
  font-weight: bold;
  font-size: 15px;
  line-height: 38px;
  text-transform: none;
  color: white;
  width: 60px;
  height: 40px;
  padding: 0 15px;
  position: absolute;
  top: 0;
  right: -70px;
}
.mw-720px {
  max-width: 720px;
}
.connector-tool-wrapper {
  min-height: 500px;
  padding-top: 30px;
}

.btn-connector {
  background-color: $panel-bg-color;
  color: #fff;
  font-family: 'Helvetica', Arial, sans-serif;
  line-height: 36px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 7px;
  text-align: left;
  height: 40px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.step {
  width: 33.33%;
  padding: 0 10px;
  flex-basis: auto;
}

.connector-image {
  border: 1px solid $image-border;
}

.results-container {
  display: flex;
  .filters-panel {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 979px){
  .btn-go {
    position: relative;
    top: auto;
    right: auto;
    margin: 20px auto 0;
  }
}


@media screen and (max-width: 920px){
  .results-container {
    flex-direction: column;
    .filters-panel {
      width: calc(100% + 30px) !important;
      max-width: none !important;
      padding: 0;
      margin: 0 -15px;
      order: 2;
    }
    .results-list {
      order: 1;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 700px){
  .step {
    width: 100%;
  }

  .multiselect {
    margin-bottom: 10px;
  }

  .connector-image {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
@import '../css/variables';
.radio {
  padding: 10px 0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $checkbox-blue;
}
.custom-control {
  margin: 10px 0;
}
.radio .custom-control label {
  text-transform: capitalize;
}

.error {
  border: 1px solid $error-color;
}

.connector-tool-radio {
  display: flex;

  .custom-control.custom-radio {
    padding-left: 20px;
    .custom-control-label {
      padding-left: 5px;
      padding-right: 6px;

      &::before {
        left: -20px;
      }

      &::before {
        left: -15px;
      }

      span {
        padding-top: 1px;
        padding-left: 0px;
        padding-right: 8px;
        display: inline-block;
      }
    }
  }
}

.connector-category {
  .multiselect__single {
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.connector_tool_inner{
  .product-list {
    width: 100%;
  }
}
</style>
