<template>
  <b-row class="m-0">
    <a
      :href="getUrl(product)"
      class="ml-auto mr-auto"
    >
      <b-img
        :src="product.imageUrl"
        left
        class="product-link-image col-auto"
      />
    </a>
    <div
      class="float-left col-12 col-sm pl-2"
    >
      <a :href="getUrl(product)">
        <b class="product-link">
          {{ product.name }}
        </b>
      </a>
      <div
        v-if="product.price"
        class="text-secondary product-price">
        ${{ product.price }}
      </div>
      <div
        class="text-secondary product-description">
        {{ product.shortDescription }}
      </div>
      <div
        v-if="product.discontinued"
        class="discontinued"
      >
        Discontinued
      </div>
    </div>
  </b-row>
</template>

<script>
import productsMixin from '../../mixins/products';

export default {
  name: 'ProductListItem',
  mixins: [productsMixin],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    lastItem: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     *  Return link on product.
     *
     *  @param {Object} product - Product information
     */
    getUrl(product) {
      return this.getProductUrl(product, this.$store.state);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../css/variables';

.discontinued {
  color: $discontinued;
  font-size: 14px;
  font-weight: bold;
}

img {
  &.product-link-image {
    border: 1px solid rgb(212, 212, 212);
    margin-right: 20px;
  }
}

.product-link {
  cursor: pointer;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #000;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.product-description {
  color: rgb(126, 126, 126);
  font-size: 15px;
  line-height: 21px;
}

@media screen and (max-width: 979px) {
  .product-link {
    font-size: 14px;
    line-height: 20px;
  }
  .product-description {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 575px) {
  .product-group {
    margin-left: 0;
  }

  img.product-link-image {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
</style>
