import { DEFAULT_SELECTED_FILTERS } from 'store';

export const SET_PRODUCT_SYSTEMS = 'setProductSystems';
export const SET_PRODUCT_BRANDS = 'setProductBrands';
export const SET_FILTERS = 'setFilters';
export const SET_SELECTED_CATEGORIES = 'setSelectedCategories';
export const SET_SELECTED_BRANDS = 'setSelectedBrands';
export const SET_SELECTED_TYPE = 'setSelectedType';
export const SET_SELECTED_TEXT = 'setSelectedText';
export const SET_SELECTED_FAMILY_SYSTEM = 'setSelectedFamilySystem';
export const SET_SELECTED_ATTRIBUTES = 'setSelectedAttributes';
export const SET_PRODUCTS = 'setProducts';
export const SET_PRODUCTS_META = 'setProductsMeta';
export const SET_SELECTED_PAGE = 'setSearchPage';
export const SET_SELECTED_PER_PAGE = 'setSearchPerPage';
export const SET_SELECTED_SKU_OR_SYSTEM = 'setSelectedSkuOrSystem';
export const SET_IS_SEARCH_LOADING = 'setIsSearchLoading';
export const SET_IS_PRODUCT_LOADING = 'setIsProductLoading';
export const SET_PRODUCT_DETAILS = 'setProductDetails';
export const SET_PRODUCT_ERROR = 'setProductError';
export const CLEAR_SELECTED_FILTERS = 'clearSelectedFilters';
export const SET_SELECTED_FILTERS = 'setSelectedFilters';
export const SET_MENU_ITEMS = 'setMenuItems';
export const SET_HIDE_SEARCH_VIEW = 'setHideSearchView';
export const SET_UPDATE_SEARCH_PANEL = 'setUpdateSearchPanel';
export const SAVE_SEARCH_FILTERS = 'saveSearchFilters';
export const SET_SEARCH_ID = 'setSearchId';
export const SET_CONNECTOR_CATEGORIES = 'setConnectorCategories';
export const SET_CONNECTOR_TYPES_ONE = 'setConnectorTypesOne';
export const SET_CONNECTOR_TYPES_TWO = 'setConnectorTypesTwo';
export const SET_SHIPMENT_STATUS = 'setShipmentStatus';
export const SET_IS_SHIPMENT_FOUND = 'setIsShipmentFound';
export const SET_SHIPMENT_ERROR = 'setShipmentError';
export const SET_TOKEN = 'setToken';
export const SET_APP_DATA = 'setAppData';
export const SET_B2B_FLAG = 'setB2bFlag';
export const SET_THEME_COLORS = 'setB2bColors';
export const SET_KEEP_FILTERS = 'setKeepFilters';

/* eslint-disable no-param-reassign */

/**
 * Set grade for child categories to display them as tree.
 *
 * @param {Object} category Vuex commit handler
 * @param {Array} categories Categories array.
 */
function setChildCategoryGrade(category, categories) {
  if (category.childrenIds && category.childrenIds.length) {
    categories
      .filter(element => category.childrenIds.indexOf(element.id) >= 0)
      .map(childCategory => {
        childCategory.grade = category.grade + 1;
        setChildCategoryGrade(childCategory, categories);
        return childCategory;
      });
  }
}

export default {
  /**
   * Set Product Systems.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} productSystems - list of product systems from API.
   */
  [SET_PRODUCT_SYSTEMS]: (state, productSystems) => {
    state.productSystems = productSystems;
  },

  /**
   * Set Product Brands.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} productBrands - list of product brands from API.
   */
  [SET_PRODUCT_BRANDS]: (state, productBrands) => {
    state.productBrands = productBrands;
  },

  /**
   * Set Filters, update categories to set them grade.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} adaptiveFilter - adaptive filter from API.
   */
  [SET_FILTERS]: (state, adaptiveFilter) => {
    adaptiveFilter.categories.forEach(category => {
      if (!category.grade) {
        category.grade = 1;
        setChildCategoryGrade(category, adaptiveFilter.categories);
      }
    });

    state.filters = adaptiveFilter;
  },

  /**
   * Set categories selected for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} categories - categories list.
   */
  [SET_SELECTED_CATEGORIES]: (state, categories) => {
    state.selectedFilters.categories = categories.filter(el => el && el.id);
  },

  /**
   * Set brands selected for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} brands - brands list.
   */
  [SET_SELECTED_BRANDS]: (state, brands) => {
    state.selectedFilters.manufacturers = brands.filter(el => el && el.id);
  },

  /**
   * Set type for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {String} type - search type.
   */
  [SET_SELECTED_TYPE]: (state, type) => {
    state.selectedFilters.type = type;
  },

  /**
   * Set text for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {String} text - search text.
   */
  [SET_SELECTED_TEXT]: (state, text) => {
    state.selectedFilters.term = text;
  },

  /**
   * Set system family for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {String} systemFamily - search system family.
   */
  [SET_SELECTED_FAMILY_SYSTEM]: (state, systemFamily) => {
    state.selectedFilters.systemFamily = systemFamily;
  },

  /**
   * Set attributes for products search.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} attributes - attributes.
   */
  [SET_SELECTED_ATTRIBUTES]: (state, attributes) => {
    state.selectedFilters.attributes = attributes;
  },

  /**
   * Set products.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} products - products.
   */
  [SET_PRODUCTS]: (state, products) => {
    state.products = products;
  },

  /**
   * Set products meta.
   *
   * @param {Object} state - Vuex state.
   * @param {Array} meta - products meta.
   */
  [SET_PRODUCTS_META]: (state, meta) => {
    state.productsMeta = meta;
  },

  /**
   * Set products search page.
   *
   * @param {Object} state - Vuex state.
   * @param {Number} page - search page.
   */
  [SET_SELECTED_PAGE]: (state, page) => {
    state.selectedFilters.page = page;
  },

  /**
   * Set products search page.
   *
   * @param {Object} state - Vuex state.
   * @param {Number} perPage - Per page number.
   */
  [SET_SELECTED_PER_PAGE]: (state, perPage) => {
    state.selectedFilters.perPage = perPage;
  },

  /**
   * Set products search page.
   *
   * @param {Object} state - Vuex state.
   * @param {number} flag - Flag if not need update filters.
   */
  [SET_KEEP_FILTERS]: (state, flag) => {
    state.selectedFilters.keepFilters = !!flag;
  },

  /**
   * Set search parameter skuOrSystem.
   *
   * @param {Object} state - Vuex state.
   * @param {Array|null} type - sku or system.
   */
  [SET_SELECTED_SKU_OR_SYSTEM]: (state, type) => {
    state.selectedFilters.skuOrSystem = type;
  },

  /**
   * Set flag isSearchLoading.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} isLoading - true or false.
   */
  [SET_IS_SEARCH_LOADING]: (state, isLoading) => {
    state.isSearchLoading = Boolean(isLoading);
  },

  /**
   * Set flag isProductLoading.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} isLoading - true or false.
   */
  [SET_IS_PRODUCT_LOADING]: (state, isLoading) => {
    state.isProductLoading = Boolean(isLoading);
  },

  /**
   * Set flag isLoading.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} details - product details.
   */
  [SET_PRODUCT_DETAILS]: (state, details) => {
    state.productError = {};
    state.product = details;
    state.searchId = details.productId;
  },

  /**
   * Set error from loading product details.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} error - response error.
   */
  [SET_PRODUCT_ERROR]: (state, error) => {
    state.productError = error;
    state.product = {};
  },

  /**
   * Clear selected filters.
   *
   * @param {Object} state - Vuex state.
   */
  [CLEAR_SELECTED_FILTERS]: state => {
    state.selectedFilters = { ...DEFAULT_SELECTED_FILTERS };
  },

  /**
   * Set selected filters.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} filters - selected filters.
   */
  [SET_SELECTED_FILTERS]: (state, filters) => {
    state.selectedFilters = { ...DEFAULT_SELECTED_FILTERS, ...filters };
  },

  /**
   * Set menu items.
   *
   * @param {Object} state - Vuex state.
   * @param {Object} items - menu items.
   */
  [SET_MENU_ITEMS]: (state, items) => {
    state.menu.items = items;
  },

  /**
   * Set flag of hide search view.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} flag - true if need hide search view.
   */
  [SET_HIDE_SEARCH_VIEW]: (state, flag) => {
    state.hideSearchView = flag;
  },

  /**
   * Set flag of update search panel
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} flag - true if need to update search panel on search.
   */
  [SET_UPDATE_SEARCH_PANEL]: (state, flag) => {
    state.updateSearchPanel = flag;
  },

  /**
   * Save selected search filters to history.
   *
   * @param {Object} state - Vuex state.
   */
  [SAVE_SEARCH_FILTERS]: state => {
    const filterIndex = state.searchHistory.findIndex(
      el => el.searchId === state.searchId,
    );
    if (filterIndex < 0) {
      state.searchHistory.push({
        searchId: state.searchId,
        filters: state.selectedFilters,
      });
    } else {
      state.searchHistory[filterIndex].filters = state.selectedFilters;
    }
  },

  /**
   * Set search ID.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} id - tid based on page and time.
   */
  [SET_SEARCH_ID]: (state, id) => {
    state.searchId = id.toString();
  },

  /**
   * Save connector categories.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} categories - categories from api response.
   */
  [SET_CONNECTOR_CATEGORIES]: (state, categories) => {
    state.connector.categories = categories;
  },

  /**
   * Save connector types one.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} types - types from api response.
   */
  [SET_CONNECTOR_TYPES_ONE]: (state, types) => {
    state.connector.typesOne = types;
  },

  /**
   * Save connector types two.
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} types - types from api response.
   */
  [SET_CONNECTOR_TYPES_TWO]: (state, types) => {
    state.connector.typesTwo = types;
  },

  /**
   * Save shipment status information JSON
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} data - JSON data from API response.
   */
  [SET_SHIPMENT_STATUS]: (state, data) => {
    state.shipmentStatusJSON = data;
  },

  /**
   * Save flag if shipment info found or not
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} flag - true or false.
   */
  [SET_IS_SHIPMENT_FOUND]: (state, flag) => {
    state.isShipmentFound = flag;
  },

  /**
   * Save error if getting shipment info failed
   *
   * @param {Object} state - Vuex state.
   * @param {Boolean} message - error message.
   */
  [SET_SHIPMENT_ERROR]: (state, message) => {
    state.shipmentStatusError = message;
  },

  /**
   * Set b2b token.
   * @param state
   * @param productId
   */
  [SET_TOKEN]: (state, token) => {
    state.token = token;
  },

  /**
   * Set params from php while create app.
   * @param state
   * @param data
   */
  [SET_APP_DATA]: (state, { appName, data }) => {
    state[appName] = data;
  },

  /**
   * Flag if page is b2b.
   * @param state
   * @param b2b
   */
  [SET_B2B_FLAG]: (state, b2b) => {
    state.b2b = b2b;
  },

  /**
   * Set b2b colors.
   *
   * @param state
   * @param colors
   */
  [SET_THEME_COLORS]: (state, colors) => {
    state.colors = colors;
  },
};
/* eslint-enable no-param-reassign */
