import _ from 'lodash';
import {
  getSystems,
  getBrands,
  searchProducts,
  getProduct,
  getMenuItems,
  sendEvalRequestEmail,
  sendContactEmail,
  getCategories,
  getConnectorTypes,
  checkShipmentStatus,
} from 'data';
import {
  SET_PRODUCT_SYSTEMS,
  SET_PRODUCT_BRANDS,
  SET_FILTERS,
  SET_PRODUCTS,
  SET_PRODUCTS_META,
  SET_SELECTED_PAGE,
  SET_IS_SEARCH_LOADING,
  SET_PRODUCT_DETAILS,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_ATTRIBUTES,
  SET_SELECTED_BRANDS,
  SET_SELECTED_FAMILY_SYSTEM,
  SET_SELECTED_SKU_OR_SYSTEM,
  SET_SELECTED_FILTERS,
  SET_MENU_ITEMS,
  SET_PRODUCT_ERROR,
  SET_IS_PRODUCT_LOADING,
  SET_HIDE_SEARCH_VIEW,
  SAVE_SEARCH_FILTERS,
  SET_CONNECTOR_CATEGORIES,
  SET_CONNECTOR_TYPES_ONE,
  SET_CONNECTOR_TYPES_TWO,
  SET_IS_SHIPMENT_FOUND,
  SET_SHIPMENT_ERROR,
  SET_SHIPMENT_STATUS,
  SET_KEEP_FILTERS,
} from 'store/mutations';
import {
  GET_FILTERS_FOR_SEARCH,
  GET_FILTERS,
  GET_SELECTED_FILTERS,
  GET_TOKEN,
  GET_HISTORY_FILTERS,
} from 'store/getters';
import { DEFAULT_FILTERS } from 'store';
import { searchFilters } from '../data';

export const LOAD_PRODUCT_SYSTEMS = 'loadProductSystems';
export const LOAD_PRODUCT_BRANDS = 'loadProductBrands';
export const SEARCH_PRODUCTS = 'searchProductsAction';
export const LOAD_PRODUCT = 'loadProduct';
export const SEARCH_BY_CATEGORY = 'searchByCategoryAction';
export const SEARCH_BY_BRAND = 'searchByManufacturerAction';
export const CHANGE_SELECTED_FILTERS = 'changeSelectedFilter';
export const REMOVE_SELECTED_CATEGORY = 'removeSelectedCategory';
export const REMOVE_SELECTED_BRAND = 'removeSelectedBrand';
export const REMOVE_SELECTED_ATTRIBUTE = 'removeSelectedAttribute';
export const SAVE_SELECTED_FILTERS_AND_SEARCH = 'saveSelectedFilterAndSearch';
export const LOAD_MENU_ITEMS = 'loadMenuItems';
export const LOAD_RELATED_PRODUCTS = 'loadRelatedProducts';
export const SEARCH_BY_ATTRIBUTE = 'searchByAttributeAction';
export const SEND_EVAL_REQUEST = 'sendEvalRequestEmailAction';
export const SEND_CONTACT_FORM = 'sendContactEmailAction';
export const LOAD_CONNECTOR_CATEGORIES = 'loadConnectorCategoriesAction';
export const LOAD_CONNECTOR_TYPES_ONE = 'loadConnectorTypesOneAction';
export const LOAD_CONNECTOR_TYPES_TWO = 'loadConnectorTypesTwoAction';
export const SEARCH_BY_CONNECTOR = 'searchByConnectorsAction';
export const LOAD_SHIPMENT_STATUS = 'loadShipmentStatus';

export default {
  /**
   * Load and save product systems from api.
   *
   * @param {Object} context Vuex context.
   * @param {Object} data Params to get product systems.
   */
  [LOAD_PRODUCT_SYSTEMS]: (context, data) => {
    const params = {};
    if (data && data.oemId) {
      params.oemId = data.oemId;
    }
    if (data && data.name) {
      params.name = data.name;
    }
    getSystems(params).then(res => {
      context.commit(SET_PRODUCT_SYSTEMS, res.data ? res.data.data : []);
    });
  },

  /**
   * Remove attribute from selected filters.
   *
   * @param {Object} context Vuex context.
   * @param {Object} {id, value} attribute id and value for remove from selected filters.
   */
  [REMOVE_SELECTED_ATTRIBUTE]: ({ dispatch, getters }, { id, value }) => {
    let attributes = getters[GET_SELECTED_FILTERS].attributes.map(attr => {
      const newAttr = attr;
      if (attr.id === id) {
        newAttr.items = attr.items.filter(el => el !== value);
      }
      return newAttr;
    });
    attributes = attributes.filter(attr => attr.items && attr.items.length);

    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_ATTRIBUTES,
      data: attributes,
    });
  },

  /**
   * Remove brand from selected filters.
   *
   * @param {Object} context Vuex context.
   * @param {Object} brandId brand id for remove from selected filters.
   */
  [REMOVE_SELECTED_BRAND]: ({ dispatch, getters }, brandId) => {
    const items = getters[GET_SELECTED_FILTERS].manufacturers.filter(
      brand => brand.id !== brandId,
    );
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_BRANDS,
      data: items,
    });
  },

  /**
   * Remove category from selected filters.
   *
   * @param {Object} context Vuex context.
   * @param {Object} categoryId category id for remove from selected filters.
   */
  [REMOVE_SELECTED_CATEGORY]: ({ dispatch, getters }, categoryId) => {
    const items = getters[GET_SELECTED_FILTERS].categories.filter(
      category => category.id !== categoryId,
    );
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_CATEGORIES,
      data: items,
    });
  },

  /**
   * Load and save product brands(manufacturers) from api.
   *
   * @param {Object} context Vuex context.
   */
  [LOAD_PRODUCT_BRANDS]: context => {
    getBrands().then(res =>
      context.commit(SET_PRODUCT_BRANDS, res.data ? res.data.data : []),
    );
  },

  /**
   * Search Products, load filters and products.
   * Search based on state.selectedFilters.
   *
   * @param {Object} context Vuex context.
   */
  [SEARCH_PRODUCTS]: _.debounce(
    (context, { keepSelectedPage, keepFilters }) => {
      if (!keepSelectedPage) {
        context.commit(SET_SELECTED_PAGE, 1);
      }

      context.commit(SET_KEEP_FILTERS, keepFilters);
      context.commit(SAVE_SEARCH_FILTERS);

      if (!keepFilters) {
        searchFilters(context.getters[GET_FILTERS_FOR_SEARCH]).then(res => {
          if (res.data) {
            if (res.data.data.adaptiveFilter) {
              let adaptiveFilter = res.data.data.adaptiveFilter;
              context.commit(SET_FILTERS, adaptiveFilter);

              if (adaptiveFilter.categories) {
                // let categoryWithProducts = adaptiveFilter.categories.filter(el => el.productsCount);
                // if (categoryWithProducts.length === 1 && !context.getters[GET_SELECTED_FILTERS].categories.length) {
                //   context.commit(SET_SELECTED_CATEGORIES, categoryWithProducts);
                // };

                const categories = context.getters[
                  GET_SELECTED_FILTERS
                ].categories.map(category => {
                  if (category && !category.name) {
                    return adaptiveFilter.categories.find(
                      el => parseInt(el.id, 10) === parseInt(category.id, 10),
                    );
                  }
                  return category;
                });
                context.commit(SET_SELECTED_CATEGORIES, categories);
              }

              if (adaptiveFilter.manufacturers) {
                const manufacturers = context.getters[
                  GET_SELECTED_FILTERS
                ].manufacturers.map(manufacturer => {
                  if (!manufacturer.name) {
                    return adaptiveFilter.manufacturers.find(
                      el =>
                        parseInt(el.id, 10) === parseInt(manufacturer.id, 10),
                    );
                  }
                  return manufacturer;
                });
                context.commit(SET_SELECTED_BRANDS, manufacturers);
              }
            }
          }
        });
      }

      // context.commit(SET_IS_SEARCH_LOADING, true);
      searchProducts(context.getters[GET_FILTERS_FOR_SEARCH]).then(res => {
        if (res.data) {
          context.commit(SET_PRODUCTS, res.data.data);
          context.commit(SET_PRODUCTS_META, res.data.meta);
        }
        //context.commit(SET_IS_SEARCH_LOADING, false);
        context.commit(SET_HIDE_SEARCH_VIEW, false);
      });
    },
    10,
  ),

  /**
   * Save selected filters and search.
   *
   * @param {Object} context Vuex context.
   * @param {Object} {key, data} key - filter name, data - filter data.
   */
  [SAVE_SELECTED_FILTERS_AND_SEARCH]: (
    { commit, dispatch },
    { key, data, keepSelectedPage, keepFilters },
  ) => {
    if (key) commit(key, data);
    dispatch(SEARCH_PRODUCTS, { keepSelectedPage, keepFilters });
  },

  /**
   * Save filter to store by key and data.
   *
   * @param {Object} context Vuex context.
   * @param {Object} {key, data} key - filter name, data - filter data.
   */
  [CHANGE_SELECTED_FILTERS]: ({ dispatch, getters }, { key, data }) => {
    let newData = data;
    switch (key) {
      case SET_SELECTED_SKU_OR_SYSTEM: {
        if (getters[GET_SELECTED_FILTERS].skuOrSystem === data) newData = null;
        break;
      }
      case SET_SELECTED_FAMILY_SYSTEM: {
        break;
      }
      case SET_SELECTED_ATTRIBUTES: {
        const newAttributes = getters[GET_SELECTED_FILTERS].attributes.filter(
          el => el.id !== data.id,
        );
        if (data.items && data.items.length) {
          newAttributes.push(data);
        }
        newData = newAttributes;
        break;
      }
      case SET_SELECTED_CATEGORIES: {
        newData = getters[GET_FILTERS].categories.filter(
          item => data.items.indexOf(item.id) >= 0,
        );
        break;
      }
      case SET_SELECTED_BRANDS: {
        newData = getters[GET_FILTERS].manufacturers.filter(
          item => data.items.indexOf(item.id) >= 0,
        );
        break;
      }
      default:
        break;
    }
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, { key, data: newData });
  },

  /**
   * Get product details by key. Save it to state.
   *
   * @param {Object} context Vuex context.
   * @param {String} productKey product key for get product details.
   */
  [LOAD_PRODUCT]: (context, productKey) => {
    context.commit(SET_IS_PRODUCT_LOADING, true);
    context.commit(SET_PRODUCT_DETAILS, []);
    getProduct({
      term: productKey,
      token: context.getters[GET_TOKEN],
    })
      .then(res => {
        if (res.data) {
          context.commit(SET_PRODUCT_DETAILS, res.data.data);
          context.commit(SET_IS_PRODUCT_LOADING, false);
        }
      })
      .catch(error => {
        context.commit(SET_PRODUCT_ERROR, error.response);
        context.commit(SET_IS_PRODUCT_LOADING, false);
      });
  },

  /**
   * Search by connector.
   *
   * @param {Object} context - Vuex context.
   * @param {String} connectors - Array with connectors params .
   */
  [SEARCH_BY_CONNECTOR]: ({ dispatch }, connectors) => {
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_FILTERS,
      data: {
        type: 'connectors',
        categories: connectors.category ? [connectors.category] : [],
        connectors,
      },
    });
  },

  /**
   * Clear state, set selected category id.
   *
   * @param {Object} context Vuex context.
   * @param {String} category category for search.
   */
  [SEARCH_BY_CATEGORY]: ({ dispatch }, category) => {
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_FILTERS,
      data: {
        categories: category.id > 1 ? [category] : [],
        type: 'all',
      },
    });
  },

  /**
   * Clear state, set selected brand id.
   *
   * @param {Object} context Vuex context.
   * @param {String} brand brand for search.
   */
  [SEARCH_BY_BRAND]: ({ dispatch }, brand) => {
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_FILTERS,
      data: {
        manufacturers: brand.id > 1 ? [brand] : [],
        type: 'all',
      },
    });
  },

  /**
   * Clear state, set selected category id and attribute.
   *
   * @param {Object} context Vuex context.
   * @param {String} params params for search.
   */
  [SEARCH_BY_ATTRIBUTE]: ({ dispatch }, params) => {
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_FILTERS,
      data: {
        categories: [{ id: params.categoryId }],
        attributes: [
          {
            id: params.attributeId,
            items: [params.attributeValue],
          },
        ],
        type: 'all',
      },
    });
  },

  /**
   * Load menu items and save to store.
   *
   * @param {Object} context Vuex context.
   * @param {String} categoryId category id for search.
   */
  [LOAD_MENU_ITEMS]: context => {
    getMenuItems({ token: context.getters[GET_TOKEN] }).then(res => {
      if (res.data) {
        context.commit(SET_MENU_ITEMS, res.data.data);
      }
    });
  },

  /**
   * Load connector categories and save it to store.
   *
   * @param {Object} context Vuex context.
   */
  [LOAD_CONNECTOR_CATEGORIES]: context =>
    getCategories({ token: context.getters[GET_TOKEN] }).then(res => {
      if (res.data) {
        context.commit(SET_CONNECTOR_CATEGORIES, res.data);
      }
    }),

  /**
   * Load connector one types.
   *
   * @param {Object} context Vuex context.
   * @param {Object} params Params for search connector types.
   */
  [LOAD_CONNECTOR_TYPES_ONE]: (context, params) =>
    getConnectorTypes({ ...params, token: context.getters[GET_TOKEN] }).then(
      res => {
        if (res.data) {
          context.commit(SET_CONNECTOR_TYPES_ONE, res.data.data);
        }
      },
    ),

  /**
   * Load connector two types.
   *
   * @param {Object} context Vuex context.
   * @param {Object} params Params for search connector types.
   */
  [LOAD_CONNECTOR_TYPES_TWO]: (context, params) =>
    getConnectorTypes({ ...params, token: context.getters[GET_TOKEN] }).then(
      res => {
        if (res.data) {
          context.commit(SET_CONNECTOR_TYPES_TWO, res.data.data);
        }
      },
    ),

  /**
   * Load related products.
   *
   * @param {Object} context Vuex context.
   * @param {String} productId product id.
   */
  [LOAD_RELATED_PRODUCTS]: ({ commit, dispatch, getters }, productId) => {
    const filters = getters[GET_HISTORY_FILTERS](productId);
    let searchFilters = {};
    if (!filters) {
      searchFilters = {
        productId: productId || null,
        type: 'relatedProduct',
      };
    } else {
      searchFilters = filters.filters;
    }

    commit(SET_FILTERS, { ...DEFAULT_FILTERS });
    commit(SET_PRODUCTS, []);
    dispatch(SAVE_SELECTED_FILTERS_AND_SEARCH, {
      key: SET_SELECTED_FILTERS,
      data: searchFilters,
    });
  },

  /**
   * Send Email.
   *
   * @param {Object} context Vuex context.
   * @param {Object} params info to send email.
   */
  [SEND_EVAL_REQUEST]: (context, params) =>
    sendEvalRequestEmail(params).then(response => response),

  /**
   * Send Contact Us Email.
   *
   * @param {Object} context Vuex context.
   * @param {Object} params info to send email.
   */
  [SEND_CONTACT_FORM]: (context, params) =>
    sendContactEmail(params).then(response => response),

  /**
   * Get shipment status JSON.
   *
   * @param {Object} context Vuex context.
   * @param {Object} params info to send email.
   */
  [LOAD_SHIPMENT_STATUS]: (context, purchaseOrderNumber) =>
    checkShipmentStatus(purchaseOrderNumber)
      .then(res => {
        if (res.data && Object.keys(res.data).length !== 0) {
          context.commit(SET_SHIPMENT_STATUS, res.data);
          context.commit(SET_IS_SHIPMENT_FOUND, true);
        } else {
          context.commit(SET_IS_SHIPMENT_FOUND, false);
        }
      })
      .catch(() => {
        context.commit(
          SET_SHIPMENT_ERROR,
          'An error occurred. Please try again later.',
        );
        context.commit(SET_IS_SHIPMENT_FOUND, false);
      }),
};
