<template>
  <div
    v-if="loading"
    class="cover-spin"
  >
    <div class="loader"/>
  </div>
</template>

<script>
export default {
  name: 'CoverSpin',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../css/variables';

.cover-spin.main-cover {
  background-color: rgba(255, 255, 255, 1);
  z-index: $zidx-supper;
}

.cover-spin {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: $zidx-supper;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border: 5px solid $border-base-color;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    padding: 2px;
    border-width: 2px;
    border-style: solid;
    border-color: #888 transparent transparent transparent;
    border-radius: 50%;
    animation: ringrotate 0.8s infinite cubic-bezier(0.5, 0.49, 0.49, 0.49);
  }
}

@keyframes ringrotate {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
</style>
