<template>
  <button class="goTop" v-if="isVisible" @click="backToTop">
    <svg-icon
      icon-id="arrow-up"
    />
  </button>
</template>

<script>
import SvgIcon from './SvgIcon';

export default {
  name: 'BackToTop',
  components: {
    SvgIcon,
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    initToTopButton: function () {
      $(document).bind('scroll', function () {
        var backToTopButton = $('.goTop');
        if ($(document).scrollTop() > 250) {
          backToTopButton.addClass('isVisible');
          this.isVisible = true;
        } else {
          backToTopButton.removeClass('isVisible');
          this.isVisible = false;
        }
      }.bind(this));
    },
    backToTop: function () {
      $('html,body').stop().animate({
        scrollTop: 0
      }, 'slow', 'swing');
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.initToTopButton();
    });
  }
}
</script>

<style lang="scss" scoped>
  .goTop {
    border-radius: 5px;
    background-color: #ccc;
    position: fixed;
    width: 45px;
    height: 45px;
    display: block;
    right: 25px;
    bottom: 55px;
    border: none;
    opacity: 0;
    z-index: -1;

    &:hover {
      opacity: 1;
      background-color: #bbb;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .isVisible {
    opacity: .8;
    z-index: 4;
    transition: all .4s ease-in;
  }
</style>
