<template>
  <div
    v-if="showOptions"
    class="mb-3"
  >
    <div class="options-panel">
      <label class="options-panel-label">
        Options
      </label>
    </div>
    <b-list-group>
      <div v-if="showSelectedBrands">
        <b-list-group-item
          v-for="item in selectedFilters.manufacturers"
          :key="item.id"
          :value="item.name"
        >
          {{ item.name }}
          <svg-icon
            icon-id="close"
            icon-class="mt-1 float-right icon close-icon"
            @click="removeBrand(item.id)"
          />
        </b-list-group-item>
      </div>
      <div v-if="showSelectedCategories">
        <b-list-group-item
          v-for="item in selectedFilters.categories"
          :key="item.id"
          :value="item.name"
        >
          {{ item.name }}
          <svg-icon
            icon-id="close"
            icon-class="mt-1 float-right icon"
            @click="removeCategory(item.id)"
          />
        </b-list-group-item>
      </div>
      <div v-if="showSelectedAttributes">
        <div
          v-for="item in selectedFilters.attributes"
          :key="item.id"
        >
          <b-list-group-item
            v-for="attribute in item.items"
            :key="attribute"
            :value="attribute"
          >
            {{ attribute }}
            <svg-icon
              icon-id="close"
              icon-class="mt-1 float-right icon"
              @click="removeAttribute({ id: item.id, value: attribute })"
            />
          </b-list-group-item>
        </div>
      </div>
    </b-list-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SvgIcon from '../SvgIcon.vue';
import { GET_SELECTED_FILTERS } from '../../store/getters';
import {
  REMOVE_SELECTED_CATEGORY,
  REMOVE_SELECTED_BRAND,
  REMOVE_SELECTED_ATTRIBUTE,
} from '../../store/actions';

export default {
  name: 'FiltersPanelOptions',
  components: {
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      selectedFilters: GET_SELECTED_FILTERS,
    }),
    /**
     *  Check if need show options section.
     *
     *  @return boolean
     */
    showOptions() {
      return Boolean(
        this.showSelectedCategories ||
          this.showSelectedBrands ||
          this.showSelectedAttributes,
      );
    },
    /**
     *  Check if need show selected categories.
     *
     *  @return boolean
     */
    showSelectedCategories() {
      return (
        this.selectedFilters.categories &&
        this.selectedFilters.categories.length
      );
    },
    /**
     *  Check if need show selected attributes.
     *
     *  @return boolean
     */
    showSelectedAttributes() {
      return (
        this.selectedFilters.attributes &&
        this.selectedFilters.attributes.length
      );
    },
    /**
     *  Check if need show selected brands.
     *
     *  @return boolean
     */
    showSelectedBrands() {
      return (
        this.selectedFilters.manufacturers &&
        this.selectedFilters.manufacturers.length
      );
    },
  },
  methods: {
    ...mapActions({
      removeCategory: REMOVE_SELECTED_CATEGORY,
      removeBrand: REMOVE_SELECTED_BRAND,
      removeAttribute: REMOVE_SELECTED_ATTRIBUTE,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/css/variables.scss';

.options-panel {
  background-color: $panel-bg-color;
  height: 40px;
  margin-bottom: 0;
  padding: 0 25px;
  &-label {
    color: #fff;
    font-family: 'Helvetica', Arial, sans-serif;
    line-height: 40px;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }
}

.icon-close {
  cursor: pointer;
}

</style>

<style lang="scss">
  @import '@/css/variables.scss';

</style>
