var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"related-products"},[_c('cover-spin',{attrs:{"loading":_vm.isRelatedSearchLoading}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideSearchView),expression:"!hideSearchView"}],staticClass:"row related-products-inside"},[(_vm.relatedProducts && _vm.relatedProducts.length)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"mt-3 col-12 col-md-8 col-xl-9 float-right pl-3"},_vm._l((_vm.relatedProducts),function(categoryGroup,categoryIndex){return _c('b-card',{key:categoryIndex,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.accordionIndex(categoryIndex)),expression:"accordionIndex(categoryIndex)"}],staticClass:"p-2 card-header",attrs:{"header-tag":"header","role":"tab"}},[_c('svg-icon',{attrs:{"icon-id":"arrow-down","icon-class":"when-opened mt-1 float-left icon"}}),_c('svg-icon',{attrs:{"icon-id":"arrow-right","icon-class":"when-closed mt-1 float-left icon"}}),_c('b',[_vm._v(_vm._s(categoryGroup.category))])],1),_c('b-collapse',{attrs:{"id":_vm.accordionIndex(categoryIndex),"visible":_vm.isCategoryVisible(categoryIndex),"accordion":"related-product-accordion","role":"tabpanel"}},[_c('b-card-body',_vm._l((categoryGroup.items),function(attributeGroup,attributeIndex){return _c('b-container',{key:attributeIndex,staticClass:"bv-example-row"},[_c('b-row',[_c('b-col',{staticClass:"pl-2 col-12 col-md mb-3 mb-md-0 mt-4 mt-md-0"},[_c('b',{staticClass:"bv-example-title"},[_vm._v(_vm._s(attributeGroup.attribute))])]),_c('b-col',{staticClass:"p-0 col-12 col-md-10",attrs:{"cols":"10"}},_vm._l((attributeGroup.items),function(itemsGroup,itemsIndex){return _c('b-container',{key:itemsIndex,staticClass:"bv-example-row"},[_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12","lg":""}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                              _vm.toggleIndex(
                                itemsIndex,
                                attributeIndex,
                                categoryIndex
                              )
                            ),expression:"\n                              toggleIndex(\n                                itemsIndex,\n                                attributeIndex,\n                                categoryIndex,\n                              )\n                            "}],staticClass:"group-title"},[_c('svg-icon',{attrs:{"icon-id":"arrow-down","icon-class":"when-opened mt-1 float-left icon"}}),_c('svg-icon',{attrs:{"icon-id":"arrow-right","icon-class":"when-closed mt-1 float-left icon"}}),_c('b',{staticClass:"bv-example-group"},[_vm._v(_vm._s(itemsGroup.attribute))])],1)]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"12","lg":"9"}},[_c('b-collapse',{staticClass:"mt-1 ml-2",attrs:{"id":_vm.toggleIndex(
                                itemsIndex,
                                attributeIndex,
                                categoryIndex
                              ),"visible":""}},_vm._l((itemsGroup.products),function(product,productIndex){return _c('div',{key:productIndex},[_c('b',{staticClass:"product-name"},[_c('a',{attrs:{"href":_vm.getUrl(product)}},[_vm._v(_vm._s(product.name))])]),(product.price)?_c('span',{staticClass:"product-price"},[_vm._v("\n                                $"+_vm._s(product.price)+"\n                              ")]):_vm._e(),_c('br'),_c('div',{staticClass:"product-description"},[_vm._v("\n                                "+_vm._s(product.shortDescription)+"\n                              ")]),(
                                  _vm.isLastItem(
                                    productIndex,
                                    itemsGroup.products
                                  )
                                )?_c('hr'):_vm._e()])}),0)],1)],1),(_vm.isLastItem(itemsIndex, attributeGroup.items))?_c('hr'):_vm._e()],1)}),1)],1),(_vm.isLastItem(attributeIndex, categoryGroup.items))?_c('hr'):_vm._e()],1)}),1)],1)],1)}),1),_c('filters-panel')],1):_vm._e(),(!_vm.relatedProducts || !_vm.relatedProducts.length)?_c('div',[_vm._v("\n      There are no related products.\n    ")]):_vm._e()]),_c('svg-sprite')],1)}
var staticRenderFns = []

export { render, staticRenderFns }