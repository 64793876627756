<template>
  <div class="mb-3">
    <multiselect
      v-model="searchBrand"
      :options="brandOptions"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="true"
      :hide-selected="false"
      :preserve-search="true"
      :show-labels="false"
      placeholder="Select OEM"
      label="name"
      track-by="name"
      class="mb-3 custom-multiselect custom-multiselect--left custom-multiselect--square"
    >
      <template
        slot="tag"
        slot-scope="props"
      >
        {{ props.option.name }}
      </template>
    </multiselect>
    <multiselect
      id="ajax"
      v-model="searchSystem"
      :options="systemOptionsWithSelected"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="true"
      :hide-selected="false"
      :preserve-search="true"
      :options-limit="300"
      :show-labels="false"
      placeholder="Select System"
      label="name"
      track-by="name"
      class="mb-3 custom-multiselect custom-multiselect--square custom-multiselect--right"
      @search-change="asyncFindSystem"
    >
      <template
        slot="tag"
        slot-scope="props"
      >
        {{ props.option.name }}
      </template>
    </multiselect>
    <button
      class="btn btn-dark w-100"
      :style="'backgroundColor: ' + themeColors.startGradient"
      @click="goToSearchPage"
    >
      Go
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { LOAD_PRODUCT_SYSTEMS, LOAD_PRODUCT_BRANDS } from 'store/actions';
import { SET_PRODUCT_SYSTEMS } from 'store/mutations';
import {
  GET_PRODUCT_SYSTEMS,
  GET_PRODUCT_BRANDS,
  GET_THEME_COLORS,
} from 'store/getters';
import productsMixin from '../../mixins/products';

export default {
  name: 'FiltersPanelSelectSystem',
  mixins: [productsMixin],
  data() {
    return {
      searchBrand: null,
      searchSystem: '',
    };
  },
  computed: {
    ...mapGetters({
      systemOptions: GET_PRODUCT_SYSTEMS,
      brandOptions: GET_PRODUCT_BRANDS,
      themeColors: GET_THEME_COLORS,
    }),

    /**
     * Params from app div.
     */
    dataset() {
      return this.$store.state.selectSystemPanel;
    },

    /**
     * Add selected system to brand options.
     */
    systemOptionsWithSelected() {
      let options = this.systemOptions;
      if (this.searchSystem && this.searchSystem.id) {
        options.unshift(this.searchSystem);
      }
      return options;
    },
  },
  watch: {
    /**
     *  Upload list of product systems when brand changed.
     */
    searchBrand(newValue, oldValue) {
      if (newValue) {
        if (oldValue) {
          this.searchSystem = {};
        }
        this[LOAD_PRODUCT_SYSTEMS]({oemId: newValue ? newValue.id : null});
      }
    },
  },
  mounted() {
    this[LOAD_PRODUCT_BRANDS]();
    this[LOAD_PRODUCT_SYSTEMS]();
    if (this.dataset && this.dataset.brandId) {
      this.searchBrand = {
        id: this.dataset.brandId,
        name: this.dataset.brandName,
      };
      this.searchSystem = {
        id: this.dataset.systemId,
        name: this.dataset.systemName,
      };
    }
  },
  methods: {
    ...mapActions([LOAD_PRODUCT_BRANDS, LOAD_PRODUCT_SYSTEMS]),
    ...mapMutations([SET_PRODUCT_SYSTEMS]),

    /**
     *  Upload list of product systems by search text.
     *
     *  @params {String} searchText - text to search systems.
     */
    asyncFindSystem(searchText) {
      this[LOAD_PRODUCT_SYSTEMS]({
        oemId: this.searchBrand ? this.searchBrand.id : null,
        name: searchText,
      });
    },

    /**
     * Redirect to search page with selected params.
     */
    goToSearchPage() {
      let url = '';
      if (this.searchSystem && this.searchSystem.id) {
        url = this.getProductUrl(this.searchSystem, this.$store.state);
      } else if (this.searchBrand) {
        if (this.$store.state.b2b) {
          url = `b2b?type=all&brand=${this.searchBrand.name}&token=${
            this.$store.state.token
          }`;
        } else {
          url = `/search/system/${this.searchBrand.name}`;
        }
      }
      if (url) {
        window.location = url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-dark {
  display: block;
  width: 75px !important;
  height: 40px;
  font-family: HelveticaNeueBd, Arial, Tahoma, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 40px;
  padding: 0;
  border: none;
  margin-right: 0;
  margin-left: auto;
  text-transform: none;
  &:hover,
  &:focus {
    color: #fff;
    box-shadow: none;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
}
</style>
