<template>
  <div
v-if="productsMeta"
class="pagination row">
    <div
      class="col-12 col-sm-6"
    >
      <label class="text-center text-sm-left text-white pagination-text justify-content-left ">
        Showing {{ productsMeta.from }} - {{ productsMeta.to }} of
        {{ productsMeta.total }}
      </label>
      <b-form-select
        v-model="productsMeta.per_page"
        :options="[
          { value: 15, text: '15/page' },
          { value: 30, text: '30/page' },
          { value: 50, text: '50/page' },
          { value: 100, text: '100/page'},
          { value: 200, text: '200/page' },
          ]"
        size="sm"
        style="width:120px; font-size: 14px;"
        @change="setProductPerPage"
      >
      </b-form-select>
    </div>
    <b-pagination
      :total-rows="productsMeta.total"
      :value="productsMeta.current_page"
      :per-page="productsMeta.per_page"
      class="product-list-pagination float-right col-12 col-sm-6 mb-0 justify-content-center justify-content-sm-end"
      variant="dark"
      align="right"
      size="md"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
      @change="setProductPage"
    />
    <div class="clearfix" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_PRODUCTS_META } from 'store/getters';
import { SET_SELECTED_PAGE } from 'store/mutations';
import { SET_SELECTED_PER_PAGE } from 'store/mutations';
import { SAVE_SELECTED_FILTERS_AND_SEARCH } from 'store/actions';

export default {
  name: 'ProductListPagination',
  computed: {
    ...mapGetters({
      productsMeta: GET_PRODUCTS_META,
    }),
  },
  methods: {
    ...mapActions([SAVE_SELECTED_FILTERS_AND_SEARCH]),
    /**
     *  Save product list page to storage, update search result.
     *
     *  @params {Number} page - page for pagination product list
     */
    setProductPage(page) {
      this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
        key: SET_SELECTED_PAGE,
        data: page,
        keepSelectedPage: true,
        keepFilters: true,
      });
    },

    /**
     *  Save product list per page number to storage, update search result.
     *
     *  @params {Number} perPage - per page number for pagination product list
     */
    setProductPerPage(perPage) {
      this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
        key: SET_SELECTED_PER_PAGE,
        data: perPage,
        keepSelectedPage: false,
        keepFilters: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/css/variables.scss';

.pagination {
  background-color: $panel-bg-color;
  &-text {
    font-family: 'Helvetica', Arial, sans-serif;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    padding: 0 25px;
  }
}

.product-list-pagination {
  background: transparent;
  padding: 0 25px;

  :global(.page-link) {
    font-family: 'Helvetica', Arial, sans-serif;
    background: transparent;
    color: white;
    line-height: 40px;
    font-size: 13px;
    padding: 0 4px;
    border: none;
    outline: none !important;
    z-index: 0 !important;
  }

  :global(.page-item) {
    &:global(.active) {
      :global(.page-link) {
        background-color: transparent;
        color: white;
        text-decoration: underline;
        border: none;
      }
    }
  }

  :global(.page-item) {
    &:global(.disabled) {
      :global(.page-link) {
        color: white;
        background-color: transparent;
      }
    }
  }
}
</style>
