<template>
  <div class="col-12 col-md-4 col-xl-3 mt-3 float-left filters-panel">
    <filters-panel-options v-show="showOptions" />
    <filters-panel-types />

    <filters-panel-check-box-group
      :value="selectedCategories"
      :change-function="selectCategory"
      :items="filters.categories"
      list-name="Categories"
      list-id="categories"
    />
    <filters-panel-check-box-group
      :value="selectedBrands"
      :change-function="selectBrand"
      :items="filters.manufacturers"
      :show-more="10"
      list-name="OEM"
      list-id="manufacturers"
    />
    <filters-panel-radio-group
      v-model="selectedSystemFamily"
      :change-function="selectSystemFamily"
      :items="filters.systemFamilies"
      :show-more="10"
      list-name="Series"
      list-id="systemFamilies"
      item-name="systemFamily"
    />
    <div
      v-for="attributeGroup in filters.attributes"
      :key="attributeGroup.id"
    >
      <filters-panel-check-box-group
        :change-function="selectAttribute"
        :value="selectedAttributes[attributeGroup.id]"
        :list-name="attributeGroup.name"
        :list-id="attributeGroup.id.toString()"
        :items="attributeGroup.data"
        :display-in-filter="attributeGroup.displayInFilter"
        :show-more="5"
        name-field="value"
        value-field="value"
      />
    </div>
    <back-to-top/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FiltersPanelCheckBoxGroup from '../filters-panel/FiltersPanelCheckBoxGroup.vue';
import FiltersPanelRadioGroup from '../filters-panel/FiltersPanelRadioGroup.vue';
import FiltersPanelOptions from '../filters-panel/FiltersPanelOptions.vue';
import FiltersPanelTypes from '../filters-panel/FiltersPanelTypes.vue';
import BackToTop from '../BackToTop';
import {
  GET_FILTERS,
  GET_SELECTED_FILTERS,
  GET_SELECTED_ATTRIBUTES,
  GET_SELECTED_CATEGORIES,
  GET_SELECTED_BRANDS,
  GET_SELECTED_SYSTEM_FAMILY,
} from '../../store/getters';
import {
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_BRANDS,
  SET_SELECTED_FAMILY_SYSTEM,
  SET_SELECTED_ATTRIBUTES,
} from '../../store/mutations';

export default {
  name: 'FiltersPanel',
  components: {
    FiltersPanelOptions,
    FiltersPanelCheckBoxGroup,
    FiltersPanelRadioGroup,
    FiltersPanelTypes,
    BackToTop,
  },
  props: {
    showOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectCategory: SET_SELECTED_CATEGORIES,
      selectAttribute: SET_SELECTED_ATTRIBUTES,
      selectBrand: SET_SELECTED_BRANDS,
      selectSystemFamily: SET_SELECTED_FAMILY_SYSTEM,
    };
  },
  computed: {
    ...mapGetters({
      filters: GET_FILTERS,
      selectedFilters: GET_SELECTED_FILTERS,
      selectedAttributes: GET_SELECTED_ATTRIBUTES,
      selectedCategories: GET_SELECTED_CATEGORIES,
      selectedBrands: GET_SELECTED_BRANDS,
      selectedSystemFamily: GET_SELECTED_SYSTEM_FAMILY,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '../../css/variables';

.filters-panel {
  padding-right: 30px;
  padding-left: 15px;
}

.list-group-item:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
}
.list-group {
  border-bottom: 1px solid $gray-color;
}

@media screen and (max-width: 767px) {
  .filters-panel {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
