<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-wrapper"><symbol id="icon-arrow-up" viewBox="0 0 1024 1024"><path d="M0 768c0 6.552 2.499 13.102 7.499 18.101 9.997 9.998 26.206 9.998 36.203 0l442.698-442.698 442.699 442.698c9.997 9.998 26.206 9.998 36.203 0s9.998-26.206 0-36.203l-460.8-460.8c-9.997-9.998-26.206-9.998-36.203 0l-460.8 460.8c-5 5-7.499 11.55-7.499 18.102z"></path></symbol><symbol id="icon-close" viewBox="0 0 47.971 47.971"><path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"></path></symbol><symbol id="icon-arrow-right" viewBox="0 0 451.846 451.847"><path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744   L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284   c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path></symbol><symbol id="icon-arrow-down" viewBox="0 0 451.847 451.847"><path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"></path></symbol></svg>
</template>

<script>
  export default {
  }
</script>

<style lang="scss" scoped>
    .svg-wrapper {
        width: 0;
        height: 0;
    }
</style>
