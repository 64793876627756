export const GET_PRODUCT_SYSTEMS = 'getProductSystems';
export const GET_PRODUCT_BRANDS = 'getProductBrands';
export const GET_FILTERS = 'getFilters';
export const GET_FILTERS_FOR_SEARCH = 'getFiltersForSearch';
export const GET_SELECTED_FILTERS = 'getSelectedFilters';
export const GET_PRODUCTS = 'getProducts';
export const GET_PRODUCTS_META = 'getProductsMeta';
export const GET_SELECTED_ATTRIBUTES = 'getSelectedAttributes';
export const GET_IS_SEARCH_LOADING = 'getIsSearchLoading';
export const GET_PRODUCT = 'getProduct';
export const GET_PRODUCT_ERROR = 'getProductError';
export const GET_SELECTED_CATEGORIES = 'getSelectedCategories';
export const GET_SELECTED_BRANDS = 'getSelectedBrands';
export const GET_SELECTED_SYSTEM_FAMILY = 'getSelectedSystemFamily';
export const GET_MENU_ITEMS = 'getMenuItems';
export const GET_TOKEN = 'getToken';
export const GET_IS_PRODUCT_LOADING = 'getIsProductLoading';
export const GET_IS_SITE_LOADED = 'getIsSiteLoaded';
export const GET_HIDE_SEARCH_VIEW = 'getHideSearchView';
export const GET_UPDATE_SEARCH_PANEL = 'getUpdateSearchPanel';
export const GET_HISTORY_FILTERS = 'getHistoryFilters';
export const GET_CONNECTOR_CATEGORIES = 'getConnectorCategories';
export const GET_CONNECTOR_TYPES_ONE = 'getConnectorTypesOne';
export const GET_CONNECTOR_TYPES_TWO = 'getConnectorTypesTwo';
export const GET_SHIPMENT_STATUS = 'getShipmentStatus';
export const GET_IS_SHIPMENT_FOUND = 'getIsShipmentFound';
export const GET_SHIPMENT_ERROR = 'getShipmentError';
export const GET_THEME_COLORS = 'getThemeColors';

/**
 * Returns selected filters formatted for products search based on filters object.
 *
 * @param {Object} filters Selected filters
 */
function getSelectedFilters(filters, state) {
  const searchFilters = {};

  if (filters.productId) {
    searchFilters.productId = filters.productId;
  }

  if (filters.categories && filters.categories.length) {
    searchFilters.categoriesIds = [];
    filters.categories.forEach(category => {
      if (category) {
        searchFilters.categoriesIds.push(category.id);
      }
    });
  }

  if (filters.manufacturers && filters.manufacturers.length) {
    searchFilters.manufacturersIds = [];
    filters.manufacturers.forEach(manufacturer => {
      if (manufacturer) {
        searchFilters.manufacturersIds.push(manufacturer.id);
      }
    });
  }

  if (filters.type) {
    searchFilters.type = filters.type;
  }

  if (filters.term) {
    searchFilters.term = filters.term;
  }

  if (filters.systemFamily && filters.systemFamily.systemFamily) {
    searchFilters.systemFamily = filters.systemFamily.systemFamily;
  }

  if (filters.attributes && filters.attributes.length) {
    searchFilters.attributes = filters.attributes.map(attribute => {
      if (attribute.items && attribute.items.length) {
        return {
          id: attribute.id,
          values: attribute.items.map(value => value.toString()),
        };
      }

      return false;
    });
  }

  searchFilters.page = filters.page ? filters.page : 1;
  searchFilters.perPage = filters.perPage ? filters.perPage : 15;
  searchFilters.token = state.token;

  if (filters.skuOrSystem) {
    searchFilters.skuOrSystem = filters.skuOrSystem;
  }

  if (filters.connectors) {
    searchFilters.connectors = {
      connectorOne: filters.connectors.connectorOne,
      connectorTwo: filters.connectors.connectorTwo,
      categoryId: filters.connectors.category
        ? filters.connectors.category.id
        : null,
    };
  }

  if (filters.rootCategoryId) {
    searchFilters.rootCategoryId = filters.rootCategoryId;
  }

  if (filters.keepFilters) {
    searchFilters.keepFilters = filters.keepFilters;
  }

  if (state.b2b) {
    searchFilters.orderBy = [{ fieldName: 'isModel' }];
  } else {
    searchFilters.orderBy = [];
  }

  return searchFilters;
}

export default {
  /**
   * Returns theme color for b2b pages.
   */
  [GET_THEME_COLORS]: state => state.colors,

  /**
   * Get flag if product is loading.
   */
  [GET_IS_PRODUCT_LOADING]: state => state.isProductLoading,

  /**
   * Get client token.
   */
  [GET_TOKEN]: state => state.token,

  /**
   * Get menu items.
   */
  [GET_MENU_ITEMS]: state => state.menu.items,

  /**
   * Get product details from store.
   */
  [GET_PRODUCT]: state => state.product,

  /**
   * Get product error from store.
   */
  [GET_PRODUCT_ERROR]: state => state.productError,

  /**
   * Get isLoading flag.
   */
  [GET_IS_SEARCH_LOADING]: state => state.isSearchLoading,

  /**
   * Returns products.
   */
  [GET_PRODUCTS]: state => state.products,

  /**
   * Returns products meta.
   */
  [GET_PRODUCTS_META]: state => state.productsMeta,

  /**
   * Returns products systems.
   */
  [GET_PRODUCT_SYSTEMS]: state => state.productSystems,

  /**
   * Returns products brands.
   */
  [GET_PRODUCT_BRANDS]: state => state.productBrands,

  /**
   * Returns filters.
   */
  [GET_FILTERS]: state => state.filters,

  /**
   * Returns selected filters.
   */
  [GET_SELECTED_FILTERS]: state => state.selectedFilters,

  /**
   * Returns selected system family.
   */
  [GET_SELECTED_SYSTEM_FAMILY]: state => state.selectedFilters.systemFamily,

  /**
   * Returns selected categories as array.
   */
  [GET_SELECTED_CATEGORIES]: state =>
    state.selectedFilters.categories
      .filter(category => category && category.id)
      .map(category => category.id),

  /**
   * Returns selected categories as array.
   */
  [GET_SELECTED_BRANDS]: state =>
    state.selectedFilters.manufacturers.map(brand => brand.id),

  /**
   * Returns selected attributes as object.
   */
  [GET_SELECTED_ATTRIBUTES]: state => {
    const arrtibutes = {};
    state.filters.attributes.forEach(attribute => {
      const selectedAttributes = state.selectedFilters.attributes.find(
        el => parseInt(el.id, 10) === parseInt(attribute.id, 10),
      );
      arrtibutes[attribute.id] = selectedAttributes
        ? selectedAttributes.items
        : [];
    });
    return arrtibutes;
  },

  /**
   * Returns selected filters formatted for products search.
   */
  [GET_FILTERS_FOR_SEARCH]: state =>
    getSelectedFilters(state.selectedFilters, state),

  /**
   * Returns selected filters formatted for products search.
   */
  [GET_IS_SITE_LOADED]: state =>
    Boolean(state.menu && state.menu.items && state.menu.items.length),

  /**
   * Returns selected filters formatted for products search.
   */
  [GET_HIDE_SEARCH_VIEW]: state => state.hideSearchView,

  /**
   * Returns selected filters formatted for products search.
   */
  [GET_UPDATE_SEARCH_PANEL]: state => state.updateSearchPanel,

  /**
   * Returns selected filters formatted for products search.
   */
  [GET_HISTORY_FILTERS]: state => searchId =>
    state.searchHistory.find(
      el => searchId && el.searchId.toString() === searchId.toString(),
    ),

  /**
   * Returns connector categories.
   */
  [GET_CONNECTOR_CATEGORIES]: state => state.connector.categories,

  /**
   * Returns connector types one.
   */
  [GET_CONNECTOR_TYPES_ONE]: state => state.connector.typesOne,

  /**
   * Returns connector types two.
   */
  [GET_CONNECTOR_TYPES_TWO]: state => state.connector.typesTwo,

  /**
   * Returns shipment status JSON.
   */
  [GET_SHIPMENT_STATUS]: state => state.shipmentStatusJSON,

  /**
   * Returns is shipment information found or not.
   */
  [GET_IS_SHIPMENT_FOUND]: state => state.isShipmentFound,

  /**
   * Returns error response from get shipment status endpoint.
   */
  [GET_SHIPMENT_ERROR]: state => state.shipmentStatusError,
};
