<template>
  <div class="product-list">
    <div v-if="showProductList">
      <product-list-pagination />
      <br>
      <div
        v-for="(productsItem, index) in products"
        :key="index"
      >
        <div v-if="productsItem.items.length === 1">
          <div
            v-for="product in productsItem.items"
            :key="product.productId"
          >
            <product-list-item :product="product" />
            <hr v-if="index !== products.length - 1" class="my-4" />
          </div>
        </div>
        <div v-else>
          <div
            v-b-toggle="'toggle_' + index"
            class="m-1 group-title"
          >
            <svg-icon
              icon-id="arrow-down"
              icon-class="when-opened mt-1 float-left icon"
            />
            <svg-icon
              icon-id="arrow-right"
              icon-class="when-closed mt-1 float-left icon"
            />
            <b class="group-title-text">{{ productsItem.systemFamily }} (
              {{ productsItem.items.length }} )</b>
          </div>
          <div class="text-secondary ">
            {{ productsItem.description }}
          </div>
          <b-collapse :id="'toggle_' + index">
            <hr class="my-4" />
            <div
              v-for="(product, index) in productsItem.items"
              :key="product.productId"
              class="product-group"
            >
              <product-list-item :product="product" />
              <hr
                v-if="index !== productsItem.items.length - 1"
                class="my-4"
              />
            </div>
          </b-collapse>
          <div class="clearfix" />
          <hr class="my-4" />
        </div>
      </div>
      <product-list-pagination class="mt-4" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductListItem from '../product-list/ProductListItem.vue';
import ProductListPagination from '../product-list/ProductListPagination.vue';
import { GET_PRODUCTS } from '../../store/getters';
import SvgIcon from '../SvgIcon.vue';

export default {
  name: 'ProductList',
  components: {
    ProductListItem,
    ProductListPagination,
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      products: GET_PRODUCTS
    }),

    /**
     *  Check if need show product list.
     *
     *  @return boolean
     */
    showProductList() {
      return this.products && this.products.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.group-title {
  cursor: pointer;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #000;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  .group-title-text {
    margin-left: 8px;
  }
}

.product-group {
  margin-left: 60px;
}

@media screen and (max-width: 979px) {
  .group-title {
    font-size: 14px;
    line-height: 20px;
  }
  .group-title-text {
    margin-left: 4px;
  }
}

@media screen and (max-width: 575px) {
  .product-group {
    margin-left: 0;
  }
}
</style>
