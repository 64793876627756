import Vue from 'vue';
import Vuex from 'vuex';
import actions from 'store/actions';
import mutations from 'store/mutations';
import getters from 'store/getters';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const DEFAULT_SELECTED_FILTERS = {
  skuOrSystem: '',
  page: 1,
  attributes: [],
  categories: [],
  manufacturers: [],
  type: 'all',
  rootCategoryId: null,
  systemFamily: {},
  term: '',
  searchBrand: '',
  connectors: {
    category: null,
    connectorOne: null,
    connectorTwo: null,
  },
};

export const DEFAULT_FILTERS = {
  manufacturers: [],
  categories: [],
  systemFamilies: [],
  attributes: [],
};

export function createStore() {
  return new Vuex.Store({
    state: {
      searchId: null,
      colors: {
        startGradient: '',
        endGradient: '',
        b2bFontColor: '',
        b2bTitleColor: '',
      },
      menu: {
        items: [],
      },
      product: {},
      productError: {},
      isSearchLoading: false,
      isProductLoading: false,
      hideSearchView: true,
      updateSearchPanel: true,
      productSystems: [],
      productBrands: [],
      products: [],
      productsMeta: {},
      filters: { ...DEFAULT_FILTERS },
      selectedFilters: { ...DEFAULT_SELECTED_FILTERS },
      token: '',
      searchHistory: [],
      connector: {
        categories: [],
        typesOne: [],
        typesTwo: [],
      },
      shipmentStatusJSON: {},
      isShipmentFound: false,
      shipmentStatusError: '',
    },
    actions,
    mutations,
    getters,
    plugins: [
      createPersistedState({
        key: 'addon',
        paths: ['searchHistory'],
      }),
    ],
  });
}

export default [];
