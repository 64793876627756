export default {
  methods: {
    /**
     * Return link to product page.
     *
     * @param {Object} product - Product data.
     * @param {boolean} b2b - Flag if this is b2b page
     * @return {string}
     */
    initSearchId() {
      if (!this.$route.query.searchId) {
        const searchId = new Date().valueOf();
        const route = { ...this.$route };
        route.query = route.query
          ? Object.assign(route.query, { searchId })
          : { searchId };
        history.replaceState(
          null,
          null,
          '?' +
            Object.keys(route.query)
              .map(key => key + '=' + route.query[key])
              .join('&'),
        );
      }
      return this.$route.query.searchId || searchId;
    },

    changeUrl(url) {
      const route = { ...this.$route };
      history.replaceState(
        'Object',
        'Title',
        url + '?' +
        Object.keys(route.query)
          .map(key => key + '=' + route.query[key])
          .join('&'),
      );
    }
  },
};
