import axios from 'axios';

const api = process.env.VUE_APP_API_URL;

export function getSystems(params) {
  return axios
    .get(`${api}/v1/search/system-products`, { params })
    .then(response => response);
}

export function getBrands() {
  return axios.get(`${api}/v1/search/manufacturers`);
}

export function search(params) {
  return axios.get(`${api}/v1/search/es`, { params: { body: JSON.stringify(params) } });
}

export function searchProducts(params) {
  return axios.get(`${api}/v1/search/es/products`, { params: { body: JSON.stringify(params)} });
}

export function searchFilters(params) {
  return axios.get(`${api}/v1/search/es/filters`, { params: { body: JSON.stringify(params)} });
}

export function getProduct(params) {
  return axios.get(`${api}/v1/product-details`, { params });
}

export function getMenuItems(params) {
  return axios.get(`${api}/v1/top-menu`, { params });
}

export function sendEvalRequestEmail(params) {
  return axios.post(`/api/sendEvalRequestEmail`, params);
}

export function sendContactEmail(params) {
  return axios.post(`/api/sendContactEmail`, params);
}

export function getCategories(params) {
  return axios.get(`${api}/v1/connector/categories`, { params });
}

export function getConnectorTypes(params) {
  return axios.get(`${api}/v1/connector/list`, { params });
}

export function checkShipmentStatus(params) {
  return axios.get(`${api}/v1/proline-shipment-status`, { params });
}

export default [];
