<template>
  <div class="w-100">
    <div v-if="items && items.length">
      <div v-b-toggle="listIdList">
        <svg-icon
          icon-id="arrow-down"
          icon-class="when-opened mt-1 float-left icon"
        />
        <svg-icon
          icon-id="arrow-right"
          icon-class="when-closed mt-1 float-left icon"
        />
        <b>{{ listName }}</b>
        <svg-icon
          v-if="value && value.systemFamily"
          icon-id="close"
          icon-class="mt-1 float-right icon"
          @click.stop="clearValue"
        />
      </div>
      <b-collapse
        :id="listIdList"
        visible
      >
        <b-form-group>
          <b-form-radio-group
            :id="radioID"
            v-model="value"
            :name="selectedName"
            stacked
            @change="changeValue"
          >
            <b-form-radio
              v-for="(item, index) in itemsShowFirst"
              :key="index"
              :disabled="item.productsCount === 0"
              :value="item"
              class="w-100"
            >
              {{ item.systemFamily }} ({{ item.productsCount }})
            </b-form-radio>
            <div v-if="isShowMore">
              <div
                v-if="displayShowMore"
                @click="toggleShowMore"
              >
                <span class="text-info">
                  Show More
                </span>
              </div>
              <div
                v-if="!displayShowMore"
                :id="listIdShowMore"
              >
                <b-form-radio
                  v-for="(item, index) in itemsShowSecond"
                  :key="index"
                  :disabled="item.productsCount === 0"
                  :value="item"
                  class="w-100"
                >
                  {{ item.systemFamily }} ({{ item.productsCount }})
                </b-form-radio>
              </div>
              <div
                v-if="!displayShowMore"
                @click="toggleShowMore"
              >
                <span class="text-info">
                  Show Less
                </span>
              </div>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </b-collapse>
      <hr class="my-4">
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SvgIcon from '../SvgIcon.vue';
import { CHANGE_SELECTED_FILTERS } from '../../store/actions';

export default {
  name: 'FiltersPanelRadioGroup',
  components: {
    SvgIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    listName: {
      type: String,
      default: '',
    },
    showMore: {
      type: Number,
      default: 0,
    },
    listId: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    changeFunction: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listIdList: `${this.listId}List`,
      listIdShowMore: `${this.listId}ShowMore`,
      selectedName: `selected${this.listId}`,
      radioID: `radio${this.listId}`,
      displayShowMore: true,
      noInputEmit: false,
    };
  },
  computed: {
    /**
     *  Check if need showMore section.
     *
     *  @return boolean
     */
    isShowMore() {
      return this.showMore && this.items.length > this.showMore;
    },

    /**
     *  Items displaying in first part (before showMore section).
     *
     *  @return Array
     */
    itemsShowFirst() {
      return this.isShowMore ? this.items.slice(0, this.showMore) : this.items;
    },

    /**
     *  Items displaying in second part (in showMore section).
     *
     *  @return Array
     */
    itemsShowSecond() {
      return this.items.slice(this.showMore);
    },
  },
  methods: {
    ...mapActions([CHANGE_SELECTED_FILTERS]),
    /**
     *  Save value to selected filters when it change.
     */
    changeValue(value) {
      this[CHANGE_SELECTED_FILTERS]({ key: this.changeFunction, data: value });
    },
    /**
     *  Clear value.
     */
    clearValue() {
      this.changeValue(null);
    },

    /**
     *  Toggle parameter displayShowMore.
     */
    toggleShowMore() {
      this.displayShowMore = !this.displayShowMore;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../css/variables';

:global(.custom-radio) {
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
    background-color: $checkbox-color !important;
  }
}

:global(.custom-control-label) {
  width: 100%;
}
</style>
