<template>
  <div
    v-if="loading"
    class="spinner">
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
</template>

<script>
export default {
  name: 'FloatingPointsLoader',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../css/variables';

body {
  overflow: hidden;
}
.spinner {
  position: relative;
  & div {
    width: 15px;
    height: 15px;
    position: absolute;
    left: -15px;
    top: 30px;
    background-color: #333;
    border-radius: 50%;
    animation: move 4s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
  }
  & div:nth-child(2) {
    animation-delay: 150ms;
  }
  & div:nth-child(3) {
    animation-delay: 300ms;
  }
  & div:nth-child(4) {
    animation-delay: 450ms;
  }
}

@keyframes move {
  0% {
    left: 0;
  }
  75% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
</style>
