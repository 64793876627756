<template>
  <svg
    :class="getClassName()"
    class="icon" @click="click">
    <use
      ref="svgIcon"
      xlink:href=""
      xmlns:xlink="http://www.w3.org/1999/xlink"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      default: '',
    },
    iconId: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.iconId) {
      this.$refs.svgIcon.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `#icon-${this.iconId}`,
      );
    }
  },
  methods: {
    getClassName() {
      const iconClass = this.iconClass ? this.iconClass : '';

      return `icon-${this.iconId} ${iconClass}`;
    },
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped>
.icon {
  width: 18px;
  height: 18px;
  padding: 3px;
}
.icon-satellite {
  width: 30px;
  height: 30px;
}

.icon-flag {
  height: 12px;
  width: 12px;
}

@media screen and (max-width: 979px) {
  .icon {
    width: 15px;
    height: 15px;
  }
}
</style>
