<template>
  <div
    v-if="showNoResults"
    class="no-results"
  >
    <div
      class="no-results-header"
      :style="'color: ' + themeColors.startGradient"
    >
      Nothing Found
    </div>
    <div class="no-results-explain">
      There are no products matching selected filters
    </div>
  </div>
</template>

<script>
import { GET_PRODUCTS_META, GET_THEME_COLORS } from '../store/getters';
import { mapGetters } from 'vuex';

export default {
  name: 'NoResults',
  computed: {
    ...mapGetters({
      productsMeta: GET_PRODUCTS_META,
      themeColors: GET_THEME_COLORS,
    }),

    /**
     *  Check if need show no results panel.
     *
     *  @return boolean
     */
    showNoResults() {
      return (
        this.productsMeta &&
        this.productsMeta.total === 0 &&
        !this.isSearchLoading
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.no-results {
  text-align: center;

  .no-results-header {
    padding: 30pt 0;
    font-size: 2.2em;
    font-weight: bold;
    color: #0b5787;
  }
  .no-results-explain {
    color: #7e7e7e;
    font-size: 1.4em;
  }
}
</style>
