<template>
  <div class="w-100">
    <div v-if="items && items.length">
      <div
v-b-toggle="listIdList" class="toggle-text"
>
        <svg-icon
          icon-id="arrow-down"
          icon-class="when-opened mt-1 float-left icon"
        />
        <svg-icon
          icon-id="arrow-right"
          icon-class="when-closed mt-1 float-left icon"
        />
        <b class="toggle-label">{{ listName }}</b>
      </div>
      <b-collapse :id="listIdList" :visible="!collapsed()">
        <b-form-group>
          <b-form-checkbox-group
            v-model="value"
            :name="selectedName"
            stacked
            @change="changeValue"
          >
            <div
              v-for="item in itemsShowFirst"
              :key="item[valueField]"
              :class="getItemNameClassDiv(item)"
            >
              <b-form-checkbox
                v-if="item.productsCount"
                :disabled="!item.productsCount"
                :value="item[valueField]"
                class="w-100 checkbox-form"
              >
              <span :class="getItemNameClass(item)"
                    style="cursor: pointer"
              >
                {{ item[nameField] }}
                <span v-if="item.productsCount">
                  ({{ item.productsCount }})
                </span>
              </span>
              </b-form-checkbox>

              <span
                v-if="!item.productsCount"
                :class="getItemNameClass(item)"
                style="margin-left: 15px"
              >
                {{ item[nameField] }}
              </span>

            </div>
            <div v-if="isShowMore">
              <div v-if="displayShowMore" @click="toggleShowMore">
                <span class="text-info">
                  Show More
                </span>
              </div>
              <div v-if="!displayShowMore" :id="listIdShowMore">
                <b-form-checkbox
                  v-for="item in itemsShowSecond"
                  :key="item[valueField]"
                  :disabled="!item.productsCount"
                  :value="item[valueField]"
                  class="w-100 second-checkbox-form"
                >
                  <label :class="getItemNameClass(item)">
                    {{ item[nameField] }}
                    <span v-if="item.productsCount">
                      ({{ item.productsCount }})
                    </span>
                  </label>
                </b-form-checkbox>
              </div>
              <div v-if="!displayShowMore" @click="toggleShowMore">
                <span class="text-info">
                  Show Less
                </span>
              </div>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </b-collapse>
      <hr class="my-4" >
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SvgIcon from '../SvgIcon.vue';
import { CHANGE_SELECTED_FILTERS } from '../../store/actions';

const EXPANDED = 'expanded';
const COLLAPSED = 'collapsed';
const PARTIALLY_EXPANDED = 'partially expanded';

export default {
  name: 'FiltersPanelCheckBoxGroup',
  components: {
    SvgIcon,
  },
  props: {
    changeFunction: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    nameField: {
      type: String,
      default: 'name',
    },
    valueField: {
      type: String,
      default: 'id',
    },
    listName: {
      type: String,
      default: '',
    },
    showMore: {
      type: Number,
      default: 0,
    },
    listId: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => {},
    },
    displayInFilter: {
      type: String,
      default: PARTIALLY_EXPANDED,
    },
  },
  data() {
    return {
      listIdList: `${this.listId}List`,
      listIdShowMore: `${this.listId}ShowMore`,
      selectedName: `selected${this.listId}`,
      displayShowMore: true,
      callInputEmit: true,
    };
  },
  computed: {
    /**
     *  Check if need showMore section.
     *
     *  @return boolean
     */
    isShowMore() {
      return (
        !this.expanded() && this.showMore && this.items.length > this.showMore
      );
    },

    /**
     *  Items displaying in first part (before showMore section).
     *
     *  @return Array
     */
    itemsShowFirst() {
      return this.isShowMore ? this.items.slice(0, this.showMore) : this.items;
    },

    /**
     *  Items displaying in second part (in showMore section).
     *
     *  @return Array
     */
    itemsShowSecond() {
      return this.items.slice(this.showMore);
    },
  },
  methods: {
    ...mapActions([CHANGE_SELECTED_FILTERS]),
    changeValue(value) {
      this[CHANGE_SELECTED_FILTERS]({
        key: this.changeFunction,
        data: { items: value, id: this.listId },
      });
    },
    /**
     *  Toggle parameter displayShowMore.
     */
    toggleShowMore() {
      this.displayShowMore = !this.displayShowMore;
    },

    /**
     *  Check if  group must me collapsed.
     *
     *  @return boolean
     */
    collapsed() {
      return this.displayInFilter === COLLAPSED;
    },

    /**
     *  Check if  group must me expanded.
     *
     *  @return boolean
     */
    expanded() {
      return this.displayInFilter === EXPANDED;
    },

    /**
     *  Return class for checkbox label if item has "grade" property.
     *
     *  @patams {Object} item - checkbox item.
     *  @return string
     */
    getItemNameClass(item) {
      return item.grade ? `grade-${item.grade}` : '';
    },

    getItemNameClassDiv(item) {
      return item.grade ? `div-grade-${item.grade}` : '';
    },

  },
};
</script>

<style lang="scss" scoped>
@import '../../css/variables';

:global(.custom-checkbox) {
  :global(.custom-control-input:checked ~ .custom-control-label::before) {
    background-color: $checkbox-color !important;
  }
}

.checkbox-form {
  margin: 0;
}

.div-grade-1 {
  font-weight: 700;
  background-color: #fff;
  padding-left: 5px;
  color: #777;
}

.div-grade-2 {
  font-weight: 600;
  background-color: #fff;
  padding-left: 5px;
  color: #777;
}

.div-grade-3 {
  background-color: #fff;
  padding-left: 5px;
  color: #777;
}

.div-grade-4 {
  background-color: #fff;
  padding-left: 5px;
  color: #777;
}

.grade-1 {
  width: 100%;
  padding-left: 8px;
  margin-bottom: 0;
}
.grade-2 {
  width: 100%;
  padding-left: 16px;
  margin-bottom: 0;
}
.grade-3 {
  width: 100%;
  padding-left: 24px;
  margin-bottom: 0;
}
.grade-4 {
  width: 100%;
  padding-left: 34px;
  margin-bottom: 0;
}
.grade-5 {
  width: 100%;
  padding-left: 44px;
  margin-bottom: 0;
}

.toggle-text {
  display: flex;
  align-items: center;
  .toggle-label {
    margin-left: 8px;
  }
}

.text-info {
  cursor: pointer;
}
</style>

<style lang="scss">
.checkbox-form .custom-control-label,
.custom-checkbox .custom-control-label {
  width: 100%;
  &::before {
    top: 6px;
    left: -15px;
    width: 14px;
    height: 14px;
  }
  &::after {
    width: 10px;
    height: 10px;
    top: 7px;
    left: -13px;
    background: no-repeat 75%;
  }
}

.custom-control-label {
  label {
    padding-left: 10px;
    margin: 0;
  }
  span {
    padding-left: 8px;
  }
}

.custom-control.custom-checkbox {
  margin: 0;
}

.custom-control.custom-radio {
  margin: 6px 0;
  .custom-control-label {
    width: 100%;
    padding-left: 10px;
    &::before {
      top: 6px;
      left: -15px;
      width: 14px;
      height: 14px;
    }
    &::after {
      width: 6px;
      height: 6px;
      top: 10px;
      left: -11px;
      background: no-repeat 50%;
    }
  }
}
</style>
