<template>
  <div class="container mb-4">
    <cover-spin :loading="isSearchLoading" />
    <product-list
      v-show="!isHideSearchView"
      class="mt-3 col-12 col-md-8 col-xl-9 float-right pl-3"
    />
    <filters-panel
      v-show="!isHideSearchView"
      class="search-filters-panel"
    />
    <no-results />
    <div class="clearfix" />
    <svg-sprite />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SEARCH_PRODUCTS,
  SAVE_SELECTED_FILTERS_AND_SEARCH,
} from '../store/actions';
import {
  SET_HIDE_SEARCH_VIEW,
  SET_SEARCH_ID,
  SET_SELECTED_FILTERS,
} from '../store/mutations';
import {
  GET_IS_SEARCH_LOADING,
  GET_HIDE_SEARCH_VIEW,
  GET_HISTORY_FILTERS,
  GET_SELECTED_FILTERS,
} from '../store/getters';
import FiltersPanel from '../components/filters-panel/FiltersPanel.vue';
import ProductList from '../components/product-list/ProductList.vue';
import CoverSpin from '../components/CoverSpin.vue';
import productsMixin from '../mixins/products';
import searchHistoryMixin from '../mixins/searchHistory';
import SvgSprite from '../components/SvgSprite.vue';
import NoResults from '../components/NoResults';

export default {
  name: 'SearchView',
  components: {
    FiltersPanel,
    ProductList,
    CoverSpin,
    SvgSprite,
    NoResults,
  },
  mixins: [productsMixin, searchHistoryMixin],
  computed: {
    ...mapGetters({
      isSearchLoading: GET_IS_SEARCH_LOADING,
      isHideSearchView: GET_HIDE_SEARCH_VIEW,
      getHistoryFilters: GET_HISTORY_FILTERS,
      selectedFilters: GET_SELECTED_FILTERS,
    }),
    dataset() {
      return this.$store.state.searchResults;
    },
  },
  /**
   * Load products by params in url.
   */
  mounted() {
    this.initSearchPage();
  },
  methods: {
    ...mapActions([SEARCH_PRODUCTS, SAVE_SELECTED_FILTERS_AND_SEARCH]),
    ...mapMutations([
      SET_HIDE_SEARCH_VIEW,
      SET_SEARCH_ID,
      SET_SELECTED_FILTERS,
    ]),

    /**
     * Init search filters.
     */
    initSearchPage() {
      this[SET_HIDE_SEARCH_VIEW](true);

      const searchId = this.initSearchId();
      this[SET_SEARCH_ID](searchId);
      const searchFilters = this.getHistoryFilters(searchId);
      if (searchFilters) {
        this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
          key: SET_SELECTED_FILTERS,
          data: searchFilters.filters,
          keepSelectedPage: true,
        });
        return;
      }

      const initFilters = this.initFilters();

      this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
        key: SET_SELECTED_FILTERS,
        data: initFilters,
      });
    },
  },
};
</script>
<style lang="scss">
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '../css/app';

:global(.view) {
  display: flex;
  position: relative;
}
</style>
<style lang="scss" scoped>
@import '../css/mixins';

.container {
  @include media('>=sm', '<lg') {
    max-width: 100%;
  }
}
</style>
