<template>
  <div class="related-products">
    <cover-spin :loading="isRelatedSearchLoading" />

    <div
      v-show="!hideSearchView"
      class="row related-products-inside">
      <div
        v-if="relatedProducts && relatedProducts.length"
        class="w-100">
        <div class="mt-3 col-12 col-md-8 col-xl-9 float-right pl-3">
          <b-card
            v-for="(categoryGroup, categoryIndex) in relatedProducts"
            :key="categoryIndex"
            no-body
            class="mb-1"
          >
            <b-card-header
              v-b-toggle="accordionIndex(categoryIndex)"
              header-tag="header"
              class="p-2 card-header"
              role="tab"
            >
              <svg-icon
                      icon-id="arrow-down"
                      icon-class="when-opened mt-1 float-left icon"
              />
              <svg-icon
                      icon-id="arrow-right"
                      icon-class="when-closed mt-1 float-left icon"
              />
              <b>{{ categoryGroup.category }}</b>
            </b-card-header>
            <b-collapse
              :id="accordionIndex(categoryIndex)"
              :visible="isCategoryVisible(categoryIndex)"
              accordion="related-product-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-container
                  v-for="(attributeGroup, attributeIndex) in categoryGroup.items"
                  :key="attributeIndex"
                  class="bv-example-row"
                >
                  <b-row>
                    <b-col class="pl-2 col-12 col-md mb-3 mb-md-0 mt-4 mt-md-0"><b class="bv-example-title">{{ attributeGroup.attribute }}</b></b-col>
                    <b-col
                      class="p-0 col-12 col-md-10"
                      cols="10"
                    >
                      <b-container
                        v-for="(itemsGroup, itemsIndex) in attributeGroup.items"
                        :key="itemsIndex"
                        class="bv-example-row"
                      >
                        <b-row>
                          <b-col class="p-0" cols="12" lg>
                            <div
                              v-b-toggle="
                                toggleIndex(
                                  itemsIndex,
                                  attributeIndex,
                                  categoryIndex,
                                )
                              "
                              class="group-title"
                            >
                              <svg-icon
                                icon-id="arrow-down"
                                icon-class="when-opened mt-1 float-left icon"
                              />
                              <svg-icon
                                icon-id="arrow-right"
                                icon-class="when-closed mt-1 float-left icon"
                              />
                              <b class="bv-example-group">{{ itemsGroup.attribute }}</b>
                            </div>
                          </b-col>

                          <b-col
                            class="p-0" cols="12"
                            lg="9"
                          >
                            <b-collapse
                              :id="
                                toggleIndex(
                                  itemsIndex,
                                  attributeIndex,
                                  categoryIndex,
                                )
                              "
                              visible
                              class="mt-1 ml-2"
                            >
                              <div
                                v-for="(product,
                                        productIndex) in itemsGroup.products"
                                :key="productIndex"
                              >
                                <b class="product-name">
                                  <a :href="getUrl(product)">{{ product.name }}</a>
                                </b>
                                <span v-if="product.price" class="product-price">
                                  ${{ product.price }}
                                </span>
                                <br />
                                <div class="product-description">
                                  {{ product.shortDescription }}
                                </div>
                                <hr
                                  v-if="
                                    isLastItem(
                                      productIndex,
                                      itemsGroup.products,
                                    )
                                  "
                                >
                              </div>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <hr
                          v-if="isLastItem(itemsIndex, attributeGroup.items)"
                        >
                      </b-container>
                    </b-col>
                  </b-row>
                  <hr v-if="isLastItem(attributeIndex, categoryGroup.items)" />
                </b-container>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <filters-panel />
      </div>
      <div v-if="!relatedProducts || !relatedProducts.length">
        There are no related products.
      </div>
    </div>
    <svg-sprite />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  GET_IS_SEARCH_LOADING,
  GET_PRODUCTS,
  GET_HIDE_SEARCH_VIEW,
} from '../../store/getters';
import CoverSpin from '../CoverSpin.vue';
import SvgIcon from '../SvgIcon.vue';
import FiltersPanel from '../filters-panel/FiltersPanel.vue';
import {
  SET_HIDE_SEARCH_VIEW,
  SET_SELECTED_FILTERS,
  SET_SEARCH_ID,
} from '../../store/mutations';
import { SEARCH_BY_CATEGORY, LOAD_RELATED_PRODUCTS } from '../../store/actions';
import productsMixin from '../../mixins/products';
import searchHistoryMixin from '../../mixins/searchHistory';
import SvgSprite from '../SvgSprite.vue';

export default {
  name: 'ProductViewRelatedProducts',
  components: {
    CoverSpin,
    SvgIcon,
    FiltersPanel,
    SvgSprite
  },
  mixins: [productsMixin, searchHistoryMixin],
  computed: {
    ...mapGetters({
      isRelatedSearchLoading: GET_IS_SEARCH_LOADING,
      relatedProducts: GET_PRODUCTS,
      hideSearchView: GET_HIDE_SEARCH_VIEW,
    }),
    dataset() {
      return this.$store.state.relatedProducts;
    },
  },
  mounted() {
    this[SET_SELECTED_FILTERS]({});
    this[SET_SEARCH_ID](this.dataset.productId);
    this[SET_HIDE_SEARCH_VIEW](true);
    this[LOAD_RELATED_PRODUCTS](this.dataset.productId);
  },
  methods: {
    ...mapActions([SEARCH_BY_CATEGORY, LOAD_RELATED_PRODUCTS]),
    ...mapMutations([
      SET_HIDE_SEARCH_VIEW,
      SET_SELECTED_FILTERS,
      SET_SEARCH_ID,
    ]),

    accordionIndex(index) {
      return `accordion_${index}`;
    },
    isCategoryVisible(index) {
      return index === 0;
    },
    toggleIndex(itemsIndex, attributeIndex, categoryIndex) {
      return `toggle_${itemsIndex}_${attributeIndex}_${categoryIndex}`;
    },
    isLastItem(index, items) {
      return index !== items.length - 1;
    },

    /**
     *  Return link on product.
     *
     *  @param {Object} product - Product information
     */
    getUrl(product) {
      return this.getProductUrl(product, this.$store.state);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../css/variables';

.related-products {
  position: relative;
  .related-products-inside {
    margin: 0 !important;
  }
}

.card-header {
  background-color: #7e7e7e;
  color: #fff;
  font-family: 'Helvetica', Arial, sans-serif;
  line-height: 38px;
  font-size: 14px;
  font-weight: 700;
  height: 38px;
  padding: 0 25px !important;
  display: flex;
  align-items: center;
  .icon {
    fill: #fff;
  }
  b {
    margin-left: 10px;
  }
}

.bv-example-title,
.bv-example-group,
.product-name a {
  font-family: 'Helvetica', Arial, sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

.bv-example-group {
  margin-left: 10px;
}

.product-description {
  font-size: 13px;
  color: #7e7e7e;
}

.product-price {
  margin-left: 10px;
}

</style>
