<template>
  <div class="item_image">
    <product-zoomer
      :base-images="images"
      :base-zoomer-options="zoomerOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageGalleryPanel',
  computed: {
    dataset() {
      return this.$store.state.imageGalleryPanel;
    },
    images() {
      const images = { thumbs: [], normal_size: [], large_size: [] };
      JSON.parse(decodeURIComponent(this.dataset.images)).forEach(
        (el, index) => {
          images.thumbs.push({ id: index, url: el.thumbnail });
          images.normal_size.push({ id: index, url: el.original });
          images.large_size.push({ id: index, url: el.original });
        },
      );
      return images;
    },
  },
  data() {
    return {
      zoomerOptions: {
        zoomFactor: 4,
        pane: 'pane',
        hoverDelay: 300,
        namespace: 'zoomer',
        move_by_click: false,
        scroll_items: 5,
        choosed_thumb_border_color: '#bbdefb',
        scroller_button_style: 'line',
        scroller_position: 'left',
        zoomer_pane_position: 'right',
      },
    };
  },
};
</script>
<style lang="scss">
.item_image {
  .thumb-list {
    width: 50px !important;
    height: 280px !important;
  }
  .zoomer-base-container {
    width: 330px !important;
    grid-template-columns: none;
    .preview-box {
      max-width: 278px !important;
      max-height: 278px !important;
    }
    .pane-container {
      width: 400px !important;
      height: 400px !important;
      border: #dbdbdb solid 1px;
    }
  }
}
</style>
