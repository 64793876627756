<template>
  <div class="mb-4 pb-4">
    <cover-spin :loading="isSearchLoading" />
    <div>
      <div
        class="b2b_sidebar_wrapper" style="float:none;"
      >
        <a
          class="b2b_sidebar_button"
          @click="showFilter = !showFilter"
        />
        <div class="b2b_sidebar">
          <div class="filter_block clearfix">
            <filters-panel-select-options />
          </div>
          <div
            v-show="showFilter"
            class="b2b_dropdown_filter opened"
            style="max-height: 600px; overflow: auto"
          >
            <filters-panel
              style="margin: 5px"
              :showOptions="false"
            />
          </div>
        </div>
        <filters-panel-select-system />
      </div>
      <div class="clearfix" />
    </div>
    <product-list
      v-show="!isHideSearchView"
      style="margin: 15px"
    />
    <div class="clearfix" />
    <svg-sprite />
    <no-results />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SAVE_SELECTED_FILTERS_AND_SEARCH } from '../store/actions';
import {
  SET_HIDE_SEARCH_VIEW,
  SET_SEARCH_ID,
  SET_SELECTED_FILTERS,
} from '../store/mutations';
import {
  GET_IS_SEARCH_LOADING,
  GET_HIDE_SEARCH_VIEW,
  GET_HISTORY_FILTERS,
  GET_SELECTED_FILTERS,
} from '../store/getters';
import FiltersPanel from '../components/filters-panel/FiltersPanel.vue';
import ProductList from '../components/product-list/ProductList.vue';
import FiltersPanelSelectOptions from '../components/filters-panel/FiltersPanelSelectOptions.vue';
import FiltersPanelSelectSystem from '../components/filters-panel/FiltersPanelSelectSystem.vue';
import CoverSpin from '../components/CoverSpin.vue';
import productsMixin from '../mixins/products';
import searchHistoryMixin from '../mixins/searchHistory';
import SvgSprite from '../components/SvgSprite.vue';
import NoResults from '../components/NoResults';

export default {
  name: 'SearchView',
  components: {
    FiltersPanel,
    ProductList,
    CoverSpin,
    FiltersPanelSelectOptions,
    FiltersPanelSelectSystem,
    SvgSprite,
    NoResults,
  },
  mixins: [productsMixin, searchHistoryMixin],
  data() {
    return {
      showFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      isSearchLoading: GET_IS_SEARCH_LOADING,
      isHideSearchView: GET_HIDE_SEARCH_VIEW,
      getHistoryFilters: GET_HISTORY_FILTERS,
      selectedFilters: GET_SELECTED_FILTERS,
    }),
    dataset() {
      return this.$store.state.searchResultsB2b;
    },
  },
  /**
   * Load products by params in url.
   */
  mounted() {
    this.initSearchPage();
  },
  methods: {
    ...mapActions([SAVE_SELECTED_FILTERS_AND_SEARCH]),
    ...mapMutations([
      SET_HIDE_SEARCH_VIEW,
      SET_SEARCH_ID,
      SET_SELECTED_FILTERS,
    ]),

    /**
     * Init search filters.
     */
    initSearchPage() {
      this[SET_HIDE_SEARCH_VIEW](true);

      const searchId = this.initSearchId();
      this[SET_SEARCH_ID](searchId);
      const searchFilters = this.getHistoryFilters(searchId);
      if (searchFilters) {
        this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
          key: SET_SELECTED_FILTERS,
          data: searchFilters.filters,
          keepSelectedPage: true,
        });
        return;
      }

      const initFilters = this.initFilters();

      this[SAVE_SELECTED_FILTERS_AND_SEARCH]({
        key: SET_SELECTED_FILTERS,
        data: initFilters,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../css/mixins';

.container {
  @include media('>=sm', '<lg') {
    max-width: 100%;
  }
}
</style>
<style lang="scss">
  .b2b_sidebar_wrapper {
    .filters-panel {
      width: 100% !important;
      max-width: none !important;
      padding-right: 20px;
    }
  }
</style>
